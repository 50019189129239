import { useMemo } from 'react';
import useDeviceDetect from '../../hooks/useDeviceDetect';

export const useSliderTransitionWidth = () => {
  const width2800 = useDeviceDetect(2800);
  const width2560 = useDeviceDetect(2560);
  const width2460 = useDeviceDetect(2460);
  const width2360 = useDeviceDetect(2360);
  const width2260 = useDeviceDetect(2260);
  const width2160 = useDeviceDetect(2160);
  const width2060 = useDeviceDetect(2060);
  const width1920 = useDeviceDetect(1920);
  const width1680 = useDeviceDetect(1680);
  const width1600 = useDeviceDetect(1600);
  const width1512 = useDeviceDetect(1512);
  const width1440 = useDeviceDetect(1440);
  const width1412 = useDeviceDetect(1412);
  const width1300 = useDeviceDetect(1300);
  const width1280 = useDeviceDetect(1280);
  const width1200 = useDeviceDetect(1200);
  const width1030 = useDeviceDetect(1030);

  let listOffsetForeheadForDesktop = useMemo(() => {
    let listOffsetForehead = [];
    if (width2800 && !width2560) {
      listOffsetForehead = [34, 3, -28, -59, -90, -121, -152];
    } else if (width2560 && !width2460) {
      listOffsetForehead = [33, -1, -35, -69, -104, -138, -171];
    } else if (width2460 && !width2360) {
      listOffsetForehead = [32, -4, -38, -74, -110, -144, -180];
    } else if (width2360 && !width2260) {
      listOffsetForehead = [32, -6, -42, -79, -116, -153, -190];
    } else if (width2260 && !width2160) {
      listOffsetForehead = [31, -8, -46, -85, -124, -162, -201];
    } else if (width2160 && !width2060) {
      listOffsetForehead = [30, -10, -50, -91, -132, -172, -212];
    } else if (width2060 && !width1920) {
      listOffsetForehead = [29, -13, -56, -98, -140, -182, -225];
    } else if (width1920 && !width1680) {
      listOffsetForehead = [27, -17, -63, -109, -155, -200, -246];
    } else if (width1680 && !width1600) {
      listOffsetForehead = [24, -28, -79, -131, -184, -236, -286];
    } else if (width1600 && !width1512) {
      listOffsetForehead = [22, -32, -85, -140, -196, -250, -304];
    } else if (width1512 && !width1440) {
      listOffsetForehead = [22, -36, -94, -152, -210, -266, -328];
    } else if (width1440 && !width1412) {
      listOffsetForehead = [20, -42, -101, -162, -223, -283, -345];
    } else if (width1412 && !width1300) {
      listOffsetForehead = [20, -42, -104, -166, -228, -288, -350];
    } else if (width1300 && !width1280) {
      listOffsetForehead = [19, -52, -118, -185, -253, -320, -386];
    } else if (width1280 && !width1200) {
      listOffsetForehead = [16, -52, -120, -188, -256, -324, -392];
    } else if (width1200 && !width1030) { 
      listOffsetForehead = [15, -58, -130, -205, -278, -350, -420];
    } else if (width1030) {
      listOffsetForehead = [2, -80, -166, -250, -335, -420, -506];
    }
    return listOffsetForehead;
  }, [width1920, width1600, width1300, width1200, width1440, width1680,
      width1030, width1512, width2560, width2460, width2800,
      width2260, width2160, width2360, width1280, width2060
    ]);
  return listOffsetForeheadForDesktop;
}