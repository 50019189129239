import linkedin from './Linkedin.svg';
import facebook from './Facebook.svg';
import youtube from './YouTube.svg';
import instagram from './Instagram.svg';
import twitter from './Twitter.svg';
import xing from './Xing.svg';

export const socialMedias = [
    {
        src: linkedin,
        alt: 'linkedin',
        link:'https://de.linkedin.com/company/visoplan',
    },
    {
        src:facebook,
        alt: 'facebook',
        link:'https://www.facebook.com/visoplan'
    },
    {
        src:youtube,
        alt: 'youtube',
        link:'https://www.youtube.com/channel/UCZa2YJloKK9WaaQt6YBmICA'
    },
    {
        src:instagram,
        alt: 'instagram',
        link:'https://www.instagram.com/visoplan/?hl=de'
    },
    {
        src: xing,
        alt: 'xing',
        link:'https://www.xing.com/pages/visoplan-gmbh'
    },
    {
        src:twitter,
        alt: 'twitter',
        link:'https://twitter.com/visoplan'
    }
]