import ContactUsForm from "../../components/ContactUsContainer";
import {Helmet} from 'react-helmet';
import './index.css';
import i18n from "../../i18n";
import Footer from "../../components/Footer";
import {AdressBook, Envelop, Foreheadimage, Phone} from "../../assets/Imprint";
import {useTranslation} from "react-i18next";

const Imprint = () => {
    const {t} = useTranslation();

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('ImprintMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/imprint`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/imprint" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/imprint" />
                <meta name="description" content={t('ImprintMetaDescription')} />
            </Helmet>
            <div className={'ImprintForehead'}>
                <div className={'ImprintForeheadData'}>
                    <h1>{t('Imprint')}</h1>
                    <h4>{t('ImprintSubTitle')}</h4>
                    <p className={'paragraphBold'}>{t('ImprintBoldParagraph')}</p>
                    <p id={'ImprintStreet'}>{t('ImprintStret')}</p>
                    <p id={'ImprintCity'}>13351 Berlin</p>
                    <p id={'ImprintCountry'}>{t('Germany')}</p>
                    <div className={'ImprintForeheadContactInfoBlock'}>
                        <div className={'ImprintForeheadContactInfoBlockWithIcon'}>
                            <img src={Phone} alt='Phone'/>
                            <a href="tel:123-456-7890">+49 30 555 71 779</a>
                        </div>
                        <div className={'ImprintLine'}/>
                        <div className={'ImprintForeheadContactInfoBlockWithIcon'} onClick={()=>{window.location='mailto:kontakt@visoplan.de?subject=Contact from Imprint'}}>
                            <img src={Envelop} alt='Envelop'/>
                            kontakt@visoplan.de
                        </div>
                        <div className={'ImprintLine'}/>
                        <div className={'ImprintForeheadContactInfoBlockWithIcon'}>
                            <img src={AdressBook} alt='AdressBook'/>
                            {t('ImprintAddressBook')}
                        </div>
                    </div>
                    <br />
                    <br />
                
                    <h4 className={'section'}>{t('ImprintTMG')}</h4>

                    <div className={'label'}>{t('ImprintBoldParagraph')}</div>   

                    <div className={'label'}>{t('ImportPostalAddress')}</div>
                    <div className={'value'}>{t('ImprintStret')}</div>
                    <div className={'value'}>13351 Berlin</div>

                    <div className={'label'}>{t('ImprintContact')}</div>
                    <div className={'value'}>+49 30 555 71 779</div>
                    <div className={'value'}>kontakt@visoplan.de</div>

                    <div className={'label'}>{t('ImprintLegal')}</div>
                    <div className={'value'}>Amtsgericht Berlin-Charlottenburg H 181724 B</div>                    

                    <div className={'label'}>{t('ImprintVAT')}</div>
                    <div className={'value'}>DE309833190</div>

                    <div className={'label'}>{t('ImprintEconomicID')}</div>
                    <div className={'value'}>31-412-4931</div>

                    <div className={'label'}>{t('ImprintLiabilityInsurance')}</div>
                    <div className={'value'}>Hiscox SA, Niederlassung für Deutschland</div>
                    <div className={'value'}>HISCOX SA, Arnulfstraße 31, D-80636 München</div>
                    
                    <div className={'label'}>{t('ImprintLiabilityScope')}</div>
                    <div className={'value'}>{t('ImprintLiabilityScope1')}</div>

                    <h4 className={'section'}>{t('ImprintNoteTitle')}</h4>
                    
                    <div className={'label'}>{t('ImprintCopyright')}</div>
                    <div className={'value'}>{t('ImprintStret')}</div>
                    <div className={'value'}>13351 Berlin</div>
                    
                    <div className={'label'}>{t('ImprintEditorialContent')}</div>
                    <div className={'value'}>{t('ImprintStret')}</div>
                    <div className={'value'}>13351 Berlin</div>

                    <h4 className={'section'}>{t('ImprintInfoVSBG')}</h4>
                    
                    <div className={'label'}>{t('ImprintInfoVSBG1')}</div>
                    <div className={'value'}>{t('ImprintInfoVSBG2')}</div>
                  </div>
                <img className={'ImprintForeheadImage'} src={Foreheadimage} alt={'Foreheadimage'}/>
            </div>
            <ContactUsForm/>
            <Footer/>
        </div>
    )
}
export default Imprint;