import React, { useEffect, useState } from 'react';
import useDeviceDetect from '../../hooks/useDeviceDetect';

const AnimatedScroll = ({ open, clickFunction, sliderUp, finishFunction, isWorkflow }) => {
  const [timeNow, setTimeNow] = useState(0);
  const mobile = useDeviceDetect();
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (timeNow < 10) {
          setTimeNow(timeNow + 0.05);
        } else {
          finishFunction();
          setTimeNow(0);
        }
      }, 50);
    } else {
      setTimeNow(0);
    }
  }, [timeNow, open]);

  useEffect(() => {
    if (open) {
      setTimeNow(0);
    }
  }, [open]);
  return (
    <div
      className={open ? 'DmsIndicatorActive' : 'DmsIndicator'}
      style={{
        [isWorkflow ? 'alignItems' : 'justifyContent']: `${sliderUp ? 'flex-start' : 'flex-end'}`,
      }}
      onClick={clickFunction}
    >
      {open
        ? (
            <div
              style={{
                height: `${mobile || isWorkflow ? '8px' : `${timeNow * 10}%`}`,
                width: `${!mobile && !isWorkflow ? '8px' : `${isWorkflow ? timeNow * 11.5 : timeNow * 10}%`}`,
              }}
              className={'DmsIndicatorProgressBar'}
            />
          )
        : null
      }
    </div>
  );
};
export default AnimatedScroll;
