import {lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet';
import './index.css';
import photo from "../../assets/Career/image.webp";
import photoFallback from "../../assets/Career/Image.jpg";
import {vacancies} from './vacancies.js';

import Angle from '../../assets/TaskIssue/Angle';
import i18n from "../../i18n";

const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer") );
const Footer = lazy(() =>import("../../components/Footer"));

const Career = () => {
    const germanyLanguage = i18n.language === 'de';
    const clickApplyNow = (i) => {
        if (germanyLanguage) {
            window.location = `mailto:kontakt@visoplan.de?subject=Bewerbung für die ausgeschriebene Stelle "${i.titleDE}"`
        } else {
            window.location = `mailto:kontakt@visoplan.de?subject=Application for the advertised position "${i.titleEN}"`
        }
    }
    const sendNow = () => {
        if (germanyLanguage) {
            window.location = `mailto:kontakt@visoplan.de?subject=Initativbewerbung für"`
        } else {
            window.location = `mailto:kontakt@visoplan.de?subject=Unsolicited application for"`
        }
    }
    const {t} = useTranslation();


    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('CareerMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/careers`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/careers" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/careers" />
                <meta name="description" content={t('CareerMetaDescription')} />
            </Helmet>
            <div className={'CareerForehead'}>
                <div className={'CareerForeheadData'}>
                    <h1>{t('CareerJobForeheadTitle')}</h1>
                    <p>{t('CareerJobForeheadSubTitle')}</p>
                </div>
                <img src={photo} alt='photo'
                     onError={({currentTarget}) => {
                         currentTarget.onerror = null;
                         currentTarget.src = photoFallback;
                     }}
                />
            </div>
            <div className={'CareerVacancies'}>
                <p className={'CareerNumberVacancies'}><span>{vacancies.length}</span><span>{t('openPosition')}</span>
                </p>
                <div>
                    {vacancies.map((i, key) => <div key={key} className={'CareerVacancy'}>
                        <p className={'CareerBoldTitle'}>{germanyLanguage ? i.titleDE : i.titleEN}</p>
                        <div className={'CareerTags'}>
                            {!germanyLanguage ? <>{i.tagsEn.map((j, key) => <span key={key}>{j}</span>)}</>
                                : <>{i.tagsDe.map((j, key) => <span key={key}>{j}</span>)}</>
                            }
                        </div>
                        <p className={'CareerVacancyDescription'}>{germanyLanguage ? i.descriptionDe : i.descriptionEn}</p>
                        <a style={{textDecoration: 'none'}} href={i.pdf} download>{t('downloadJobDescription')}<Angle
                            width={24} height={24}
                            color={'#4B6FFC'}
                            positionAbsolute={false}/></a>
                        <button onClick={() => clickApplyNow(i)} className={'CareerApplyNow'}>{t('applyNow')}</button>
                    </div>)}
                </div>
                <div className={'CareerNotFoundVacancies'}>
                    <p>
                        <span className={'NoVacancyNoBold'}>{t('noVacancy')}</span>
                    </p>
                    <p>
                        <b className={'NoVacancyBold'}>{t('noVacancyBold')}</b></p>
                    <button onClick={() => sendNow()}>{t('sendNow')}</button>
                </div>
            </div>
            <Suspense fallback={() => null}>
                <ContactUsForm/>
                <Footer />
            </Suspense>
        </div>
    )
}
export default Career;