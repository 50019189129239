export const addedValues = {
  ArchitectsAndEngineers: [
    'EfficientControl',
    'SignificantlyEasierClaimManagement',
    'OptimizationOfCooperation',
    'AccessProjectWithData',
    'TimeSavings',
    'Psets',
  ],
  BimManagersAndProjectControllers: [
    'OptimizationOfCooperation',
    'TimeSavings',
    'EfficientControl',
    'AlwaysMaintainAnOverview',
    'AccessProjectWithData',
    'SignificantlyEasierClaimManagement',
  ],
  GeneralContractors: [
    'ReductionofAdditionalWork',
    'FasterResponseToProblems',
    'OptimizationOfCooperation',
    'TimeSavings',
    'EfficientControl',
    'KeepTrack',
    'AccessProjectWithData',
  ],
  BuildersAndProjectDevelopers: [
    'TimelyCompletion',
    'EverythingAtGlance',
    'TraceabilityOfActivities',
    'MoreEfficientCommunication',
    'RecognizingAndEliminating',
    'AccessProjectWithData',
  ],
};

export const workflowsInfo = {
  ArchitectsAndEngineers: {
    PropertySets: 'PropertySetsDescription',
    TaskAndIssueProcesses: 'TaskAndIssueProcessesDescription',
    RFIs: 'RFIsDescription',
    AutomaticSynchronizationOperations: 'AutomaticSynchronizationOperationsDescription',
    CoordinationProcesses: 'CoordinationProcessesDescription',
    CollisionManagement: 'CollisionManagementDescription',
    WorkflowTemplates: 'WorkflowTemplatesDescription',
    BuildingComponentAnalyses: 'BuildingComponentAnalysesDescription',
    ESGrequirements: 'ESGrequirementsDescription',
    options: [
      'PropertySets',
      'TaskAndIssueProcesses',
      'RFIs',
      'AutomaticSynchronizationOperations',
      'CoordinationProcesses',
      'CollisionManagement',
      'WorkflowTemplates',
      'BuildingComponentAnalyses',
      'ESGrequirements',
    ],
  },
  BimManagersAndProjectControllers: {
    CoordinationProcesses: 'CoordinationProcessesDescription',
    AVASynchronization: 'AVASynchronizationDescription',
    RFIs: 'RFIsDescription',
    WorkflowTemplates: 'WorkflowTemplatesDescription',
    ESGrequirements: 'ESGrequirementsDescription',
    ApprovalProcesses: 'ApprovalProcessesDescription',
    TaskAndIssueProcesses: 'TaskAndIssueProcessesDescription',
    DigitalConstructionFile: 'DigitalConstructionFileDescription',
    UserAndRoleManagement: 'UserAndRoleManagementDescription',
    options: [
      'CoordinationProcesses',
      'AVASynchronization',
      'RFIs',
      'WorkflowTemplates',
      'ESGrequirements',
      'ApprovalProcesses',
      'TaskAndIssueProcesses',
      'DigitalConstructionFile',
      'UserAndRoleManagement',
    ],
  },
  GeneralContractors: {
    IssueManagement: 'IssueManagementDescription',
    ConstructionDiary: 'ConstructionDiaryDescription',
    RFIs: 'RFIsDescription',
    DigitalConstructionFile: 'DigitalConstructionFileDescription',
    ApprovalProcesses: 'ApprovalProcessesDescription',
    ConstructionDocumentation: 'ConstructionDocumentationDescription',
    options: [
      'IssueManagement',
      'ConstructionDiary',
      'RFIs',
      'DigitalConstructionFile',
      'ApprovalProcesses',
      'ConstructionDocumentation',
    ],
  },
  BuildersAndProjectDevelopers: {
    ESGrequirements: 'ESGrequirementsDescription',
    TaskAndIssueProcesses: 'TaskAndIssueProcessesDescription',
    SupplementManagement: 'SupplementManagementDescription',
    RFIs: 'RFIsDescription',
    WorkflowTemplates: 'WorkflowTemplatesDescription',
    ApprovalProcesses: 'ApprovalProcessesDescription',
    options: [
      'ESGrequirements',
      'TaskAndIssueProcesses',
      'SupplementManagement',
      'RFIs',
      'WorkflowTemplates',
      'ApprovalProcesses',
    ],
  },
};

export const sliderTitles = {
  BimManagersAndProjectControllers: [
    'BimManagersAndProjectControllersSliderTitle1',
    'BimManagersAndProjectControllersSliderTitle2',
    'BimManagersAndProjectControllersSliderTitle3',
    'BimManagersAndProjectControllersSliderTitle4',
    'BimManagersAndProjectControllersSliderTitle5',
    'BimManagersAndProjectControllersSliderTitle6',
    'BimManagersAndProjectControllersSliderTitle7',
  ],
  GeneralContractors: [
    'GeneralContractorsSliderTitle1',
    'GeneralContractorsSliderTitle2',
    'GeneralContractorsSliderTitle3',
    'GeneralContractorsSliderTitle4',
    'GeneralContractorsSliderTitle5',
    'GeneralContractorsSliderTitle6',
  ],
  BuildersAndProjectDevelopers: [
    'BuildersAndProjectDevelopersSliderTitle1',
    'BuildersAndProjectDevelopersSliderTitle2',
    'BuildersAndProjectDevelopersSliderTitle3',
    'BuildersAndProjectDevelopersSliderTitle4',
  ],
};
