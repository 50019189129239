import file1 from './dummy.pdf';

export const vacancies = [
  {
    "titleEN": "C# Software Developer f/m/d",
    "titleDE": "C# Software Developer w/m/d",
    "tagsEn": ["Berlin", "Full-Time"],
    "tagsDe": ["Berlin", "vollzeit"],
    "descriptionEn": "We are now looking for a passionate software developer who wants to be part of an exciting product!",
    "descriptionDe": "Wir suchen ab sofort einen leidenschaftlichen Software-Entwickler, der Teil eines spannendes Produktes werden will!",
    "pdf": file1
  },
  {
    "titleEN": "Working Student Digital Content Marketing f/m/d",
    "titleDE": "Werkstudent Digitales Content Marketing w/m/d",
    "tagsEn": ["Berlin", "Part-time & Student"],
    "tagsDe": ["Berlin", "Teilzeit & Werkstudent"],
    "descriptionEn": "Are you interested in practical experience in marketing? We are looking for creative and committed support!",
    "descriptionDe": "Hast du Interesse an praktischer Erfahrung im Marketing-Bereich? Wir suchen kreative und engagierte Unterstützung!",
    "pdf": file1
  },
  {
    "titleEN": "Sales Manager f/m/d",
    "titleDE": "Sales Manager / Mitarbeiter im Vertrieb w/m/d",
    "tagsEn": ["Berlin", "Full-Time"],
    "tagsDe": ["Berlin", "vollzeit"],
    "descriptionEn": "Sales, sales, sales! Show us your sales talent and convince the construction industry of digitization!",
    "descriptionDe": "Sales, Sales, Sales! Zeig uns dein Verkaufstalent und überzeuge die Baubranche von der Digitalisierung!",
    "pdf": file1
  }
  ]

