import {lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import i18n from "../../i18n";
import './index.css';

import ForeheadForm from "../../components/ForeheadForms";

const Possibilities = lazy(() =>import("./Possibilities"));
const IFCFlexContainer = lazy(() =>import("./IFCFlexContainer"));
const Communicate = lazy(() =>import("./Communicate"));
const DmsForeheadWithCarousel = lazy(() =>import("./DmsForeheadWithCarousel"));
const Footer = lazy(() =>import("../../components/Footer"));
const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));

const DMS = () => {
    const {t} = useTranslation();
    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('DMSMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/product/dms`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/product/dms" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/product/dms" />
                <meta name="description" content={t('DMSMetaDescription')} />
            </Helmet>
            <div className={'DmsForehead'}>
                <div className={"DmsForeheadInformation"}>
                    <h1>{t('DMSForeheadTitle')}</h1>
                    <p className={'subheading'}>{t('DMSForeheadText')}</p>
                    <form autoComplete="on" className={'ForeheadForm ForeheadHomepageForm'}>
                        <label>{t('ForeheadFormTitle')}</label>
                        <ForeheadForm page={'DMS'}/>
                    </form>
                </div>
                <Suspense fallback={() => null}>
                    <DmsForeheadWithCarousel />
                </Suspense>
            </div>
            <Suspense fallback={() => null}>
                <Possibilities />
                <Communicate />
                <IFCFlexContainer />
                <ContactUsForm page={'DMS'}/>
                <Footer page={'DMS'}/>
            </Suspense>
        </div>
    );
}

export default DMS;