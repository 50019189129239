import {useState} from 'react';
import './index.css';
import {useTranslation} from 'react-i18next';
import useDeviceDetect from '../../hooks/useDeviceDetect';

export const titles = {
    'Homepage': 'HOMEPAGE',
    'DMS': 'DMS',
    'TaskIssue': 'TASK & ISSUE MANAGEMENT',
    'InfoManagement': 'INFORMATION MANGEMENT',
    'IFC': 'IFC VIEWER',
    'Cloud': 'CLOUD INFRASTRUCTURE & SECURITY',
    'BIM': 'BIM MODEL MANAGEMENT',
    'PlanManagement': 'PLAN MANAGEMENT',
    'Pricing':'PRICING',
    'Workflows':'WORKFLOWS',
}

function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

function createTemplate(dataContactForm) {
    const { email, name, phone, text } = dataContactForm;
    if (text) {
        return (`
            <div>
                <p>Email: ${email}</p>
                <p>Name: ${name}</p>
                <p>${text}</p>
            </div>
        `);
    }
    return `
        <div>
            <p>Email: ${email}</p>
            <p>Name: ${name}</p>
            <p>Mobile: ${phone}</p>
        </div>
    `;
}

export async function sendMessage(dataContactForm, funcFinish, subjectPreTitle, page) {
    if (validateEmail(dataContactForm.email)) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                to: dataContactForm.email,
                subject: `${subjectPreTitle}${titles[`${page}`]}`,
                text: createTemplate(dataContactForm)
            })
        };
        const res = await fetch(`${process.env.REACT_APP_LINK}/send-email`, requestOptions)
            .then(response => response);
        if (res.status === 200) {         
            let dl = window.dataLayer || [];
            var dlEvent = {
               event: subjectPreTitle,
               page: page,
            };
            dl.push(dlEvent);
            funcFinish();
        }
    }
}

const ContactUsForm = ({page}) => {
    const {t} = useTranslation();
    const mobile = useDeviceDetect();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState('');

    return (
        <div className={'ContactUsWrapper'}>
            <div className={'ContactUsContainer'}>
                <div className={'ContactUsBackgroundPhoto'}/>
                <h5>{t('ContactUsTitle')}</h5>
                <form autoComplete="on" className={'ContactUsForm'}>
                    <h6>{t('ContactUsFormTitle')}</h6>
                    <p>{t('ContactUsFormDescription')}</p>
                    <div  className={'ContactUsFormInputContainer'} style={{
                        display: `${mobile ? 'block' : 'flex'}`, justifyContent: 'space-between',
                    }}>
                        <input
                            type='email'
                            id='email'
                            name='email'
                            autoComplete='email'
                            className={`${error && !email ? 'errorValue' : ''}`} value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={`${t('Email')}*`}
                        />
                        <input
                            id='FullName'
                            type='text'
                            name='FullName'
                            autoComplete='name' className={`${error && !name ? 'errorValue' : ''}`} value={name} onChange={(e) => setName(e.target.value)}
                               placeholder={`${t('FullName')}*`}/>
                    </div>
                    <textarea value={text} onChange={(e) => setText(e.target.value)} placeholder={`${t('Insert')}*`}/>
                    <button
                        type='submit'
                        onClick={(e) => {
                            e.preventDefault();
                        if (!email || !name) {
                            setError(true);
                        } else {
                            sendMessage({
                                email, name, text
                            }, () => {
                                setName('');
                                setEmail('');
                                setText('')
                            }, 'REQUEST CONTACT/', page)
                        }
                    }
                    }>{t('Submit')}</button>
                </form>
            </div>
        </div>
    )
}
export default ContactUsForm;