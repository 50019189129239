import DE_MOB from './EditProperties/Edit_properties_DE_MOB.webp';
import DE_WEB from './EditProperties/Edit_properties_DE_WEB.webp';
import EN_MOB from './EditProperties/Edit_properties_EN_MOB.webp';
import EN_WEB from './EditProperties/Edit_properties_EN_WEB.webp';
import EN_WEB_Fallback from './EditProperties/Edit_properties_EN_WEB.png';
import EN_MOB_Fallback from './EditProperties/Edit_properties_EN_MOB.png';

import ArchitectsAndEngineersImg1 from './ArchitectsAndEngineers/Architekt.webp';
import ArchitectsAndEngineersImg2 from './ArchitectsAndEngineers/Ingenieur.webp';
import ArchitectsAndEngineersImg1Fallback from './ArchitectsAndEngineers/Architekt.jpg';
import ArchitectsAndEngineersImg2Fallback from './ArchitectsAndEngineers/Ingenieur.jpg';

import BimManagersAndProjectControllersSlider1 from './BimManagersAndProjectControllers/1_Modellhistorie.webp';
import BimManagersAndProjectControllersSlider2 from './BimManagersAndProjectControllers/2_RFI.webp';
import BimManagersAndProjectControllersSlider3 from './BimManagersAndProjectControllers/3_AVA_synchronization.webp';
import BimManagersAndProjectControllersSlider4
  from './BimManagersAndProjectControllers/4_Rollenmanagement_blurred.webp';
import BimManagersAndProjectControllersSlider5 from './BimManagersAndProjectControllers/5_Viewpoint.webp';
import BimManagersAndProjectControllersSlider6
  from './BimManagersAndProjectControllers/6_DMS mit Berechtigungen nach Disziplin und LPH.webp';
import BimManagersAndProjectControllersSlider7 from './BimManagersAndProjectControllers/7_Attributierung_Viewer.webp';
import BimManagersAndProjectControllersSlider1Fallback from './BimManagersAndProjectControllers/1.jpg';
import BimManagersAndProjectControllersSlider2Fallback from './BimManagersAndProjectControllers/2.jpg';
import BimManagersAndProjectControllersSlider3Fallback from './BimManagersAndProjectControllers/3.jpg';
import BimManagersAndProjectControllersSlider4Fallback from './BimManagersAndProjectControllers/4.jpg';
import BimManagersAndProjectControllersSlider5Fallback from './BimManagersAndProjectControllers/5.jpg';
import BimManagersAndProjectControllersSlider6Fallback from './BimManagersAndProjectControllers/6.jpg';
import BimManagersAndProjectControllersSlider7Fallback from './BimManagersAndProjectControllers/7.jpg';

import BimManagersAndProjectControllersImg1 from './BimManagersAndProjectControllers/BIM-Manager.webp';
import BimManagersAndProjectControllersImg1Fallback from './BimManagersAndProjectControllers/BimManagersImg1.jpg';
import BimManagersAndProjectControllersImg2 from './BimManagersAndProjectControllers/Projektsteuerer.webp';
import BimManagersAndProjectControllersImg2Fallback from './BimManagersAndProjectControllers/BimManagersImg2.jpg';

import BimManagersAndProjectControllersBannerDE from './BimManagersAndProjectControllers/3Apps_DE.webp';
import BimManagersAndProjectControllersBannerEN from './BimManagersAndProjectControllers/3Apps_EN.webp';
import BimManagersAndProjectControllersBannerFallback from './BimManagersAndProjectControllers/bannerFallback.png'

import GeneralContractorsSlider1 from './GeneralContractors/1_DMS.webp';
import GeneralContractorsSlider2 from './GeneralContractors/2_RFI.webp';
import GeneralContractorsSlider3 from './GeneralContractors/3_Aktivitätenprotokoll im Dashboard.webp';
import GeneralContractorsSlider4 from './GeneralContractors/4_Aufgaben - Detailübersicht.webp';
import GeneralContractorsSlider5 from './GeneralContractors/5_Kanban-Board.webp';
import GeneralContractorsSlider6 from './GeneralContractors/6_Chat - English Example.webp';
import GeneralContractorsSlider1Fallback from './GeneralContractors/1.jpg';
import GeneralContractorsSlider2Fallback from './GeneralContractors/2.jpg';
import GeneralContractorsSlider3Fallback from './GeneralContractors/3.jpg';
import GeneralContractorsSlider4Fallback from './GeneralContractors/4.jpg';
import GeneralContractorsSlider5Fallback from './GeneralContractors/5.jpg';
import GeneralContractorsSlider6Fallback from './GeneralContractors/6.jpg';
import GeneralContractorsImg1 from './GeneralContractors/General Contractors.webp';
import GeneralContractorsImg1Fallback from './GeneralContractors/GeneralContractors.jpg';

import BuildersAndProjectDevelopersSlider1
  from './BuildersAndProjectDevelopers/1_Aktivitätenprotokoll im Dashboard.webp';
import BuildersAndProjectDevelopersSlider2 from './BuildersAndProjectDevelopers/2_RFI.webp';
import BuildersAndProjectDevelopersSlider3 from './BuildersAndProjectDevelopers/3_DMS.webp';
import BuildersAndProjectDevelopersSlider4 from './BuildersAndProjectDevelopers/4_Rollenmanagement_blurred.webp';

import BuildersAndProjectDevelopersSlider1Fallback from './BuildersAndProjectDevelopers/1.jpg';
import BuildersAndProjectDevelopersSlider2Fallback from './BuildersAndProjectDevelopers/2.jpg';
import BuildersAndProjectDevelopersSlider3Fallback from './BuildersAndProjectDevelopers/3.jpg';
import BuildersAndProjectDevelopersSlider4Fallback from './BuildersAndProjectDevelopers/4.jpg';


import BuildersAndProjectDevelopersImg1 from './BuildersAndProjectDevelopers/Business Man Hochhaus.webp';
import BuildersAndProjectDevelopersImg1Fallback from './BuildersAndProjectDevelopers/BusinessMan.jpg';

import BuildersAndProjectDevelopersImg2 from './BuildersAndProjectDevelopers/Projektentwickler.webp';
import BuildersAndProjectDevelopersImg2Fallback from './BuildersAndProjectDevelopers/Project.jpg';

import BuildersAndProjectDevelopersBannerEN from './BuildersAndProjectDevelopers/Costs_EN_WEB.webp';
import BuildersAndProjectDevelopersBannerMobEN from './BuildersAndProjectDevelopers/Costs_EN_MOB.webp';
import BuildersAndProjectDevelopersBannerDE from './BuildersAndProjectDevelopers/Costs_DE_WEB.webp';
import BuildersAndProjectDevelopersBannerMobDE from './BuildersAndProjectDevelopers/Costs_DE_MOB.webp';
import BuildersAndProjectDevelopersBannerFallback from './BuildersAndProjectDevelopers/Costs_EN_WEB.png';
import BuildersAndProjectDevelopersBannerFallbackMob from './BuildersAndProjectDevelopers/Costs_DE_WEB.png';

import HeaderGifDEMOB from './HeaderGif/Steps_GIF_DE_MOB.gif';
import HeaderGifDE from './HeaderGif/Steps_GIF_DE_WEB.gif';
import HeaderGifENMOB from './HeaderGif/Steps_GIF_EN_MOB.gif';
import HeaderGifEN from './HeaderGif/Steps_GIF_EN_WEB.gif';

export const addedValuesImgs = {
  ArchitectsAndEngineers: [{
    src: ArchitectsAndEngineersImg2,
    srcFallback: ArchitectsAndEngineersImg2Fallback
  }, {src: ArchitectsAndEngineersImg1, srcFallback: ArchitectsAndEngineersImg1Fallback}],
  BimManagersAndProjectControllers: [{
    src: BimManagersAndProjectControllersImg1,
    srcFallback: BimManagersAndProjectControllersImg1Fallback
  }, {
    src: BimManagersAndProjectControllersImg2,
    srcFallback: BimManagersAndProjectControllersImg2Fallback
  }],
  GeneralContractors: [{src: GeneralContractorsImg1, srcFallback: GeneralContractorsImg1Fallback}],
  BuildersAndProjectDevelopers: [
    {
      src: BuildersAndProjectDevelopersImg1,
      srcFallback: BuildersAndProjectDevelopersImg1Fallback
    }
    ,
    {
      src: BuildersAndProjectDevelopersImg2,
      srcFallback: BuildersAndProjectDevelopersImg2Fallback
    }
  ],
};


export const editProperties = {DE_MOB, DE_WEB, EN_MOB, EN_WEB, EN_WEB_Fallback, EN_MOB_Fallback};


export const sliderImgs = {
  BimManagersAndProjectControllers: [
    {
      src: BimManagersAndProjectControllersSlider1,
      srcFallback: BimManagersAndProjectControllersSlider1Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider2,
      srcFallback: BimManagersAndProjectControllersSlider2Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider3,
      srcFallback: BimManagersAndProjectControllersSlider3Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider4,
      srcFallback: BimManagersAndProjectControllersSlider4Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider5,
      srcFallback: BimManagersAndProjectControllersSlider5Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider6,
      srcFallback: BimManagersAndProjectControllersSlider6Fallback
    },
    {
      src: BimManagersAndProjectControllersSlider7,
      srcFallback: BimManagersAndProjectControllersSlider7Fallback
    },
  ],
  GeneralContractors: [
    {
      src: GeneralContractorsSlider1,
      srcFallback: GeneralContractorsSlider1Fallback
    },
    {
      src: GeneralContractorsSlider2,
      srcFallback: GeneralContractorsSlider2Fallback
    },
    {
      src: GeneralContractorsSlider3,
      srcFallback: GeneralContractorsSlider3Fallback
    },
    {
      src: GeneralContractorsSlider4,
      srcFallback: GeneralContractorsSlider4Fallback
    },
    {
      src: GeneralContractorsSlider5,
      srcFallback: GeneralContractorsSlider5Fallback
    },
    {
      src: GeneralContractorsSlider6,
      srcFallback: GeneralContractorsSlider6Fallback
    },
  ],
  BuildersAndProjectDevelopers: [
    {
      src: BuildersAndProjectDevelopersSlider1,
      srcFallback: BuildersAndProjectDevelopersSlider1Fallback
    },
    {
      src: BuildersAndProjectDevelopersSlider2,
      srcFallback: BuildersAndProjectDevelopersSlider2Fallback
    }, {
      src: BuildersAndProjectDevelopersSlider3,
      srcFallback: BuildersAndProjectDevelopersSlider3Fallback
    }, {
      src: BuildersAndProjectDevelopersSlider4,
      srcFallback: BuildersAndProjectDevelopersSlider4Fallback
    },
  ],
};

export const bannersImg = {
  BimManagersAndProjectControllersBannerDE,
  BimManagersAndProjectControllersBannerEN,
  BuildersAndProjectDevelopersBannerEN,
  BuildersAndProjectDevelopersBannerDE,
  BuildersAndProjectDevelopersBannerMobEN,
  BuildersAndProjectDevelopersBannerMobDE,
  BuildersAndProjectDevelopersBannerFallback,
  BuildersAndProjectDevelopersBannerFallbackMob,
  BimManagersAndProjectControllersBannerFallback
};

export const headreGifs = {
  HeaderGifDE,
  HeaderGifDEMOB,
  HeaderGifEN,
  HeaderGifENMOB,
};
