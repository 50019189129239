import FlowArrow from './FlowArrow.svg'
import Gauge from './Gauge.svg'
import WebApp from './WebApp.svg'


export const workflows = [
    {
        src: Gauge,
        background: 'linearGradient(131.57deg, #AA007B 0%, #66004A 100%);',
        title: 'TaskIssueWorkflow0Title',
        description: 'TaskIssueWorkflow0Description'
    },
    {
        src: FlowArrow,
        background: 'linearGradient(131.57deg, #AA007B 0%, #66004A 100%);',
        title: 'TaskIssueWorkflow1Title',
        description: 'TaskIssueWorkflow1Description'
    },
    {
        src: WebApp,
        background: 'linearGradient(131.57deg, #AA007B 0%, #66004A 100%);',
        title: 'TaskIssueWorkflow2Title',
        description: 'TaskIssueWorkflow2Description'
    }
]