import React from 'react';

const ArrowRight = ({color = '#FFFFFF',rotate}) => {
    return (
        <>
            <svg style={{transform: `rotate(${rotate ? '180deg' : '0deg'})`}} width="17" height="17" viewBox="0 0 17 17"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 8.49622H14" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.5 3.99622L14 8.49622L9.5 12.9962" stroke={color} strokeWidth="1.3" strokeLinecap="square"
                    strokeLinejoin="round"/>
            </svg>
        </>
    );
}
export default ArrowRight;