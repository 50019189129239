import {useEffect, useState} from "react";

const useDeviceDetect = (needSize = 768, bigger = false) => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    if (bigger) {
        return (width >= needSize);
    }
    return (width <= needSize);
}

export default useDeviceDetect