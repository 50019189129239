import {useEffect, useState, lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet';
import useScrollPosition from "../../hooks/useScrollPosition";
import i18n from "../../i18n";
import './index.css';

import {
    AutomaticNotification, AutomaticNotificationFallback,
    DigitalBlockScreen, DigitalBlockScreenFallback,
    EditIssue, EditIssueFallback,
    FirstHuge,
    FirstHugeFallback,
    FirstSmall,
    FirstSmallFallback,
    IssueManagementViaBcf, IssueManagementViaBcfFallback,
    SecondHuge,
    SecondHugeFallback,
    SecondSmall,
    SecondSmallFallback,
    video1,
    video2,
} from "../../assets/TaskIssue";

import ForeheadForm from "../../components/ForeheadForms";


const Footer = lazy(() =>import("../../components/Footer"));
const GetStartedToday = lazy(() =>import("../Homepage/GetStartedToday"));
const TaskIssueTaskCalendar = lazy(() =>import("./TaskIssueTaskCalendar"));
const TaskIssueUniqueStructure = lazy(() =>import("./TaskIssueUniqueStructure"));
const TaskIssueWorkflows = lazy(() =>import("./TaskIssueWorkflows"));
const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));
const ContentContainer = lazy(() =>import("./ContentContainer"));
const TaskIssueFlexContainer = lazy(() =>import("./TaskIssueFlexContainerInfo"));
const TaskIssueExampleTaskCreation = lazy(() =>import("./TaskIssueExampleTaskCreation"));

const TaskIssue = () => {
    const {t} = useTranslation();
    const scrollPosition = useScrollPosition();

    const [listOfStructures, setlistOfStructures] = useState([false, false, false])
    const [animationStarted, setAnimationStarted] = useState(false)
    const [activeCalendar, setActiveCalendar] = useState(0);

    const setActiveElementStructure = (id, value) => {
        setlistOfStructures(listOfStructures.map((i, index) => id === index ? value : false
        ));
    }

    useEffect(() => {
        if ((window.pageYOffset > 2800 && window.pageYOffset < 3500) && !animationStarted) {
            setAnimationStarted(true);
            setActiveElementStructure(0, true);
        }
    }, [scrollPosition])

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('TaskIssueMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/product/task-and-issue-management`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/product/task-and-issue-management" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/product/task-and-issue-management" />
                <meta name="description" content={t('TaskIssueMetaDescription')} />
            </Helmet>
            <div className={'TaskIssueForehead'}>
                <div className={'ForeheadBack'}/>
                <h1>{t('TaskIssueForeheadTitle')}</h1>
                <p className={'subheading'}>{t('TaskIssueForeheadSubTitle')}</p>
                <form autoComplete='on' className={'ForeheadForm TaskIssueForeheadForm'}>
                    <label>{t('TaskIssueForeheadFormLabel')}</label>
                    <ForeheadForm page={'TaskIssue'}/>
                </form>
                <div className={'TaskIssueForeheadIllustrationWrapper'}>
                    <img alt='' className={'TaskIssueForeheadIllustrationFirstHuge'} src={FirstHuge}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = FirstHugeFallback;
                         }}
                    />
                    <img alt='' className={'TaskIssueForeheadIllustrationFirstSmall'} src={FirstSmall}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = FirstSmallFallback;
                         }}/>
                    <img alt='' className={'TaskIssueForeheadIllustrationSecondSmall'} src={SecondSmall}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = SecondSmallFallback;
                         }}
                    />
                    <img alt='' className={'TaskIssueForeheadIllustrationSecondHuge'} src={SecondHuge}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = SecondHugeFallback;
                         }}
                    />
                </div>
            </div>
            <Suspense fallback={() => null}>
                <TaskIssueWorkflows />
                <TaskIssueExampleTaskCreation />
                <ContentContainer
                    withVideo={true} src={video1}
                    title={t('TaskIssueTaskRelationshipTitle')}
                    text={t('TaskIssueTaskRelationshipDescription')}
                    className='TaskIssueFlexContainer TaskIssueFlexContainerWithRowReverse'
                />
                <TaskIssueUniqueStructure
                    listOfStructures={listOfStructures}
                    setActiveElementStructure={setActiveElementStructure}
                />
                <TaskIssueFlexContainer
                    title={t('TaskIssueBimModelsAndBuildingComponentsTitle')}
                    text={t('TaskIssueBimModelsAndBuildingComponentsDescription')}
                    src={IssueManagementViaBcf} srcFallback={IssueManagementViaBcfFallback}
                />
                <TaskIssueTaskCalendar
                    activeCalendar={activeCalendar}
                    setActiveCalendar={setActiveCalendar}
                />
                <GetStartedToday page={'TaskIssue'} />
                <TaskIssueFlexContainer
                    style={{background: '#F6F8F9'}}
                    title={t('TaskIssueDigitalTitle')}
                    text={t('TaskIssueDigitalDescription')}
                    withLabel={true} label={t('TaskIssueDigitalLabel')}
                    imgClass={'DigitalBlockScreen'} imgStyle={{boxShadow: 'none'}}
                    src={DigitalBlockScreen} srcFallback={DigitalBlockScreenFallback}
                />
                <ContentContainer
                    srcFallback={AutomaticNotificationFallback}
                    src={AutomaticNotification}
                    title={t('AutomaticNotificationTitle')}
                    text={t('AutomaticNotificationDescription')}
                    className='TaskIssueFlexContainer TaskIssueFlexContainerWithRowReverse BiggerIlustration'
                />
            </Suspense>
            <div style={{background: '#F6F8F9'}} className={'TaskIssueFlexContainer'}>
                <div className={'TaskIssueFlexContainerInfo'}>
                    <h3>{t('TaskIssueActivitiesTitle')}</h3>
                    <p>{t('TaskIssueActivitiesDescription')}</p>
                </div>
                <div className={'TaskIssueFlexContainerInfoVideoContainer'}>
                    <video controls autoPlay muted>
                        <source src={video2}/>
                    </video>
                    <p className={'TaskIssueUnderVideoLabel'}>
                        {t('TaskIssueActivitiesUnderVideoDescription')}
                    </p>
                </div>
            </div>
            <Suspense fallback={() => null}>
                <ContentContainer
                    srcFallback={EditIssueFallback}
                    src={EditIssue}
                    title={t('TaskIssueEditIssueTitle')}
                    text={t('TaskIssueEditIssueDescription')}
                    className='TaskIssueFlexContainer TaskIssueFlexContainerWithRowReverse BiggerIlustration'
                />
                <ContactUsForm page={'TaskIssue'}/>
                <Footer page={'TaskIssue'}/>
            </Suspense>
        </div>
    )
}
export default TaskIssue;