import {useEffect, useState, lazy, Suspense} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import useDeviceDetect from "../../hooks/useDeviceDetect";
import useScrollPosition from "../../hooks/useScrollPosition";
import './index.css';

import i18n from "../../i18n";

const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));
const Footer = lazy(() =>import("../../components/Footer"));
const BicForehead = lazy(() =>import("./BicForehead"));
const BIMIntuitiveModel = lazy(() =>import("./BIMIntuitiveModel"));
const IFCFlexContainer = lazy(() =>import("./IFCFlexContainer"));
const BimAnimatedComponent = lazy(() =>import("./BimAnimatedComponent"));


const BIM = () => {
    const {t} = useTranslation();
    const mobile = useDeviceDetect();
    const smallMobile = useDeviceDetect(389);
    const iphone12PRO = useDeviceDetect(392);

    const scrollPosition = useScrollPosition();

    const [listOfIntuitiveModels, setlistOfIntuitiveModels] = useState([false, false, false, false]);
    const [offSetSlider, setOffsetSlider] = useState(mobile ? iphone12PRO ? (smallMobile ? 30 : 30) : 55 : 296);


    const [animationStarted, setAnimationStarted] = useState(false)


    const setActiveElementList = (id, value) => {
        setlistOfIntuitiveModels(listOfIntuitiveModels.map((i, index) => id === index ? value : false
        ));
    }

    const languageEn = i18n.language === 'en';
    useEffect(() => {
        if ((window.pageYOffset > 900 && window.pageYOffset < 1200) && !animationStarted) {
            setAnimationStarted(true);
            setActiveElementList(0, true);
        }
    }, [scrollPosition])

    function renderSwitchText(carouselPhotoIndex) {
        switch (carouselPhotoIndex) {
            case 1:
                return 'BIMAnimatedContentDescriptionFirst';
            case 2:
                return 'BIMAnimatedContentDescriptionSecond';
            case 3:
                return 'BIMAnimatedContentDescriptionThird';
        }
    }

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('BIMMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/product/bim-model-management`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/product/bim-model-management" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/product/bim-model-management" />
                <meta name="description" content={t('BIMMetaDescription')} />
            </Helmet>
            <Suspense fallback={() => null}>
                <BicForehead
                    mobile={mobile}
                    smallMobile={smallMobile}
                    iphone12PRO={iphone12PRO}
                />
                <BIMIntuitiveModel
                    setActiveElementList={setActiveElementList}
                    listOfIntuitiveModels={listOfIntuitiveModels}
                />
                <IFCFlexContainer />
                <BimAnimatedComponent
                    mobile={mobile}
                    languageEn={languageEn}
                    smallMobile={smallMobile}
                    iphone12PRO={iphone12PRO}
                    offSetSlider={offSetSlider}
                    setOffsetSlider={setOffsetSlider}
                    renderSwitchText={renderSwitchText}
                />
                <ContactUsForm page={'BIM'}/>
                <Footer page={'BIM'}/>
            </Suspense>
        </div>
    )
}

export default BIM;