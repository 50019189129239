import React, {useEffect, useState, lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import i18n from "../../i18n";

import useScrollPosition from "../../hooks/useScrollPosition";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import useSmallLaptopDetect from "../../hooks/useSmallLaptopSize";
import './index.css';

const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));
const Intro = lazy(() =>import("./Intro"));
const DifferentVisoplan = lazy(() =>import("./DifferentVisoplan"));
const Forehead = lazy(() =>import("./Forehead"));
const PluginsContainer = lazy(() =>import("./PluginsContainer"));
const Puzzles = lazy(() =>import("./Puzzles"));
const CustomersContainer = lazy(() =>import("./CustomersContainer"));
const GetStartedToday = lazy(() =>import("./GetStartedToday"));
const VisoplanAdvantageTriangle = lazy(() =>import("./VisoplanAdvantageTriangle"));
const FromStartToFinish = lazy(() =>import("./FromStartToFinish"));
const Footer = lazy(() =>import("../../components/Footer") );

const Homepage = () => {
    const [listPuzzles, setListPuzzles] = useState([false, false, false, true, false, false, false]);
    const [listOfWorkProcess, setLisOfWorkProcess] = useState([false, false, false, false])
    const [animationStarted, setAnimationStarted] = useState(false)

    const {t} = useTranslation();
    const language = i18n.language;
    const mobile = useDeviceDetect(968);
    const scrollPosition = useScrollPosition();
    const smallLaptop = useSmallLaptopDetect()
    const setPuzzleTrue = (indexPuzzle) => {
        setListPuzzles(listPuzzles.map((i, index) => index === indexPuzzle))
    }
    const setActiveWorkProcess = (id, value) => {
        setLisOfWorkProcess(listOfWorkProcess.map((i, index) => {
            if (id === index) {
                return value
            } else {
                return false;
            }
        }))
    }

    useEffect(() => {
        setPuzzleTrue(0);
    }, [mobile])
    useEffect(() => {
        if ((window.pageYOffset > 1800 && window.pageYOffset < 2200) && !animationStarted) {
            setAnimationStarted(true);
            setActiveWorkProcess(0, true);
        }
    }, [scrollPosition])

    const navigate = useNavigate();

    const navigateTo = (link) => {
        navigate(link);
        window.scrollTo(0, 0);
    }

    const customersInfo = [{
        name: 'Eric Ladner',
        company: 'azrepro AG',
        position: t('CustomerFirstPosition'),
        description: t('CustomerFirstDescription')
    }, {
        name: 'Robert Richter',
        company: 'INNIUS DÖ GmbH',
        position: t('CustomerSecondPosition'),
        description: t('CustomerSecondDescription')
    }, {
        name: 'Xavier Marzan',
        company: 'Filinvest Group',
        position: t('CustomerThirdPosition'),
        description: t('CustomerThirdDescription')
    }];

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('HomepageMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de" />
                <meta name="description" content={t('HomepageMetaDescription')} />
            </Helmet>
            <Suspense fallback={() => null}>
                <Forehead />
                <Intro />
                <FromStartToFinish
                    listOfWorkProcess={listOfWorkProcess}
                    setActiveWorkProcess={setActiveWorkProcess}
                />
                <Puzzles
                    navigateTo={navigateTo}
                    listPuzzles={listPuzzles}
                    setPuzzleTrue={setPuzzleTrue}
                />
                <VisoplanAdvantageTriangle
                    scrollPosition={scrollPosition}
                />
            </Suspense>
            <Suspense fallback={() => null}>
                <GetStartedToday />
                <DifferentVisoplan language={language} />
                <PluginsContainer
                    smallLaptop={smallLaptop}
                />
                <CustomersContainer
                    smallLaptop={smallLaptop}
                    customersInfo={customersInfo}
                />
                <ContactUsForm page={'Homepage'} />
                <Footer page={'Homepage'}/>
            </Suspense>
        </div>
    );
}
export default Homepage;