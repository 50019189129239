import {useState} from 'react';
import {sendMessage} from '../ContactUsContainer';
import {useTranslation} from 'react-i18next';
import './style.css';

const ForeheadForm = ({page, clName}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(false);
    const {t} = useTranslation();
    return (
        <>
            <input
                type='email'
                id='email'
                name='email'
                autoComplete='email'
                className={`${error&&!email ? 'errorValue' : ''} ${clName}`} onChange={(e) => setEmail(e.target.value)} placeholder={`${t('Email')}*`}/>
            <input 
                type='text'
                id='FullName'
                name='FullName'
                autoComplete='name' className={`${error&&!name ? 'errorValue' : ''} ${clName}`} onChange={(e) => setName(e.target.value)}
                placeholder={`${t('FullName')}*`}/>
            <input
                type='tel'
                id='Phone'
                name='Phone'
                autoComplete='tel' className={`${clName}`} onChange={(e) => setPhone(e.target.value)} placeholder={t('Phone')}/>
            <button
                style={{ marginLeft: '0px' }}
                onClick={(e) => {
                    e.preventDefault();
                    if (!email || !name) {
                        setError(true);
                    } else {
                        sendMessage({
                            email, name, phone
                        }, () => {
                            window.location = 'https://web.visoplan.de/register'
                        }, 'REQUEST DEMO/', page);
                    }
                }
                }>{t('TryForFree')}</button>
        </>)
}
export default ForeheadForm;