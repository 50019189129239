import {useEffect, useState, lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet';

import './index.css';

import useDeviceDetect from "../../hooks/useDeviceDetect";
import useScrollPosition from "../../hooks/useScrollPosition";
import i18n from "../../i18n";

const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));
const Footer = lazy(() =>import("../../components/Footer"));
const IFCFlexContainer = lazy(() =>import("./IFCFlexContainer"));
const INFEvaluation = lazy(() =>import("./INFEvaluation"));
const INFForeheadContainer = lazy(() =>import("./INFForehead"));
const TaskIssueUniqueStructure = lazy(() =>import("./TaskIssueUniqueStructure"));
const BimAnimatedComponent = lazy(() =>import("./BimAnimatedComponent"));
const IFCFlexContainer1 = lazy(() =>import("./IFCFlexContainer1"));
const IFCFlexContainer2 = lazy(() =>import("./IFCFlexContainer2"));

const InfoManagement = () => {
    const mobile = useDeviceDetect(968);
    const {t} = useTranslation();

    const [animationStarted, setAnimationStarted] = useState(false)
    const [listOfStructures, setlistOfStructures] = useState([false, false, false])
    const setActiveElementStructure = (id, value) => {
        setlistOfStructures(listOfStructures.map((i, index) => id === index ? value : false
        ));
    }

    const scrollPosition = useScrollPosition();

    const language = i18n.language;

    useEffect(() => {
        if ((scrollPosition > 850 && scrollPosition < 1700) && !animationStarted) {
            setAnimationStarted(true);
            setActiveElementStructure(0, true);
        }
    }, [scrollPosition])

    function renderSwitchText(carouselPhotoIndex) {
        switch (carouselPhotoIndex) {
            case 1:
                return 'INFAnimatedContentDescription0';
            case 2:
                return 'INFAnimatedContentDescription1';
            case 3:
                return 'INFAnimatedContentDescription2';
        }
    }

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('InfoManagementMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/product/information-management`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/product/information-management" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/product/information-management" />
                <meta name="description" content={t('InfoManagementMetaDescription')} />
            </Helmet>
            <Suspense fallback={() => null}>
                <INFForeheadContainer
                    mobile={mobile}
                    language={language}
                />
                <TaskIssueUniqueStructure
                    listOfStructures={listOfStructures}
                    setActiveElementStructure={setActiveElementStructure}
                />
                <IFCFlexContainer1 />
                <BimAnimatedComponent
                    mobile={mobile}
                    renderSwitchText={renderSwitchText}
                />
                <IFCFlexContainer2 />
                <INFEvaluation language={language} />
                <IFCFlexContainer />
                <ContactUsForm page={'InfoManagement'}/>
                <Footer page={'InfoManagement'}/>
            </Suspense>
        </div>
    );
}
export default InfoManagement;