import {lazy, Suspense} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet';
import useDeviceDetect from "../../hooks/useDeviceDetect";
import i18n from "../../i18n";
import './index.css';


const PricingPlans = lazy(() =>import("./PricingPlans"));
const CalendlyWrapper = lazy(() =>import("./CalendlyWrapper"));
const PricingForeheadDataContainer = lazy(() =>import("./PricingForeheadDataContainer"));
const Footer = lazy(() =>import("../../components/Footer"));
const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));

const Pricing = () => {
    const {t} = useTranslation();
    const mobile = useDeviceDetect();

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('PricingMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/pricing`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/pricing" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/pricing" />
                <meta name="description" content={t('PricingMetaDescription')} />
            </Helmet>
            <div className={'PricingForehead'}>
                <div className={'PricingForeheadTitle'}>
                    <h1>{t('PricingForeheadTitle')}</h1>
                    <p className={'subheading'}>{t('PricingForeheadUnderTitle')}</p>
                </div>
                <Suspense fallback={() => null}>
                    <PricingForeheadDataContainer />
                </Suspense>
            </div>
            <Suspense fallback={() => null}>
                <PricingPlans />
                <CalendlyWrapper />
                {mobile ? <ContactUsForm page={'Pricing'}/> : null}
                <Footer/>
            </Suspense>
        </div>
    )
}
export default Pricing;
