import TaskCalendar from './TaskCalendar.webp';
import KanbanCalendar from './KanbanCalendar.webp';
import Dashboard from './Dashboard.webp';
import TaskCalendarFallback from './TaskCalendar.jpg';
import KanbanCalendarFallback from './KanbanCalendar.jpg';
import DashboardFallback from './Dashboard.jpg';
export const calendarList = [
    {
        src: TaskCalendar,
        srcFallback:TaskCalendarFallback,
        title: 'TaskIssueCalendarTitle0',
        description: 'TaskIssueCalendarDescription0'
    },
    {
        src: KanbanCalendar,
        srcFallback:KanbanCalendarFallback,
        title: 'TaskIssueCalendarTitle1',
        description: 'TaskIssueCalendarDescription1'
    },
    {
        src: Dashboard,
        srcFallback:DashboardFallback,
        title: 'TaskIssueCalendarTitle2',
        description: 'TaskIssueCalendarDescription2'
    }
]