import Header from '../Header/index';
import React, { Suspense }  from 'react';

export const LayoutWrapper = ({
  children
}) => {
  return (
    <Suspense fallback={() => null}>
      <div>
        <Header />
        {children}
      </div>
    </Suspense>
  )
}