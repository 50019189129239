import {lazy, Suspense} from 'react';
import {Helmet} from 'react-helmet';
import './index.css';

import {useTranslation} from "react-i18next";

const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer") );
const Footer = lazy(() =>import("../../components/Footer"));
const AboutUsBlock = lazy(() =>import("./AboutUsBlock") );
const AboutUsOurTeam = lazy(() =>import("./AboutUsOurTeam"));
const AboutUsForehead = lazy(() =>import("./AboutUsForehead"));
const AboutUsOurVisionBlockSmallScreen = lazy(() =>import("./AboutUsOurVisionBlockSmallScreen"));

const AboutUs = () => {
    const {t, i18n} = useTranslation();

    return (
        <div>
            <Helmet>
                <html lang={i18n?.language} />
                <title>{t('AboutUsMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/about-us`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/about-us" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/about-us" />
                <meta name="description" content={t('AboutUsMetaDescription')} />
            </Helmet>
            <Suspense fallback={() => null}>
                <AboutUsForehead />
                <AboutUsOurVisionBlockSmallScreen />
                <AboutUsBlock />
                <AboutUsOurTeam />
                <ContactUsForm/>
                <Footer />
            </Suspense>
        </div>
    )
}
export default AboutUs;