import PrivacyNoticePhoto from '../../assets/PrivacyNotice/PrivacyNotice.png';
import Footer from "../../components/Footer";
import {Helmet} from 'react-helmet';
import i18n from "../../i18n";
import './index.css';
import {useTranslation} from "react-i18next";

const PrivacyNotice = () => {
    const {t} = useTranslation();

    return (
        <>
            <div>
                <Helmet>
                    <html lang={i18n.language} />
                    <title>{t('PrivacyMetaTitle')}</title>
                    <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/privacy-notice`} />
                    <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/privacy-notice" />
                    <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/privacy-notice" />
                    <meta name="description" content={t('PrivacyMetaDescription')} />
                </Helmet>
                <div className={'PrivacyNoticeForehead'}>
                    <div className={'PrivacyNoticeForeheadData'}>
                        <h1>{t('PrivacyNotice')}</h1>
                        <p className={'subheading'}>{t('PrivacyNoticeSubTitle')}</p>
                        <p className={'PrivacyNoticeParagraph'}>{t('PrivacyNoticeForeheadFirstParagraph')}</p>
                        <p className={'PrivacyNoticeBoldParagraph'}>{t('PrivacyNoticeForeheadBoldParagraph')}</p>
                        {/*<a className={'PrivacyNoticeLinkForehead'} href={'#'}*/}
                        {/*   onClick={() => UC_UI.showSecondLayer()}>{t('PrivacySettings')}</a>*/}
                        <p className={'PrivacyNoticeParagraph'}>{t('PrivacyNoticeForeheadSecondParagraph')}<a
                            href={'https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'}>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/
                            anschriften_links-node.html .</a>
                        </p>
                    </div>
                    <img src={PrivacyNoticePhoto}/>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock1')}</h4>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock1SubParagraph1Title')}</p>
                        <p className={'PrivacyNoticeText'}>
                            {t('PrivacyNoticeBlock1SubParagraph1Text1')}
                        </p>
                        <label className={'PrivacyNoticeLabelForList'}>{t('PrivacyNoticeBlock1SubParagraph1Label')}</label>
                        <ul>
                            <li>{t('PrivacyNoticeBlock1SubParagraph1listEl1')}</li>
                            <li>{t('PrivacyNoticeBlock1SubParagraph1listEl2')}</li>
                            <li>{t('PrivacyNoticeBlock1SubParagraph1listEl3')}</li>
                            <li>{t('PrivacyNoticeBlock1SubParagraph1listEl4')}</li>
                        </ul>
                        <p className={'PrivacyNoticeText'}>
                            {t('PrivacyNoticeBlock1SubParagraph1Text2')}
                        </p>
                    </div>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock1SubParagraph2Title')}</p>
                        <p className={'PrivacyNoticeText'}>{t('PrivacyNoticeBlock1SubParagraph2Text')}</p>
                    </div>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock1SubParagraph3Title')}</p>
                        <p className={'PrivacyNoticeText'}>{t('PrivacyNoticeBlock1SubParagraph3Text')}</p>
                    </div>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock1SubParagraph4Title')}</p>
                        <p className={'PrivacyNoticeText'}>{t('PrivacyNoticeBlock1SubParagraph4Text')}</p>
                    </div>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock1SubParagraph5Title')}</p>
                        <p className={'PrivacyNoticeText'}>{t('PrivacyNoticeBlock1SubParagraph5Text')}</p>
                    </div>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock2')}</h4>
                    <div className={'PrivacyNoticeFormatedText'}>
                        {t('PrivacyNoticeBlock2Text1')}
                    </div>
                    <ul className={'PrivacyNoticeCustomList'}>
                        <li>{t('PrivacyNoticeBlock2ListElement1')}<a
                            href={'https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen'}>https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
                        </li>
                        <li>{t('PrivacyNoticeBlock2ListElement2')}<a
                            href={'https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies'}>https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                        </li>
                        <li>{t('PrivacyNoticeBlock2ListElement3')}<a
                            href={'https://support.google.com/accounts/answer/61416?hl=de'}>https://support.google.com/accounts/answer/61416?hl=de</a>
                        </li>
                        <li>{t('PrivacyNoticeBlock2ListElement4')}<a
                            href={'http://www.opera.com/de/help'}>http://www.opera.com/de/help</a></li>
                        <li>{t('PrivacyNoticeBlock2ListElement5')}<a
                            href={'https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE'}>https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
                        </li>
                    </ul>
                    <div className={'PrivacyNoticeSubParagraph'}>
                        <p className={'subheading'}>{t('PrivacyNoticeBlock2SubParagraph1Title')}</p>
                        <p className={'PrivacyNoticeText'}>{t('PrivacyNoticeBlock2SubParagraph1Text')}</p>
                        <ul className={'PrivacyNoticeCustomList'}>
                            {new Array(22).fill(1).map((i, index) =>
                                <li key={index}>{t(`PrivacyNoticeBlock2SubParagraph1ListElement${index + 1}`)}</li>
                            )}
                        </ul>
                    </div>
                    {new Array(13).fill(1).map((i, index) =>
                        <div key={index} className={'PrivacyNoticeSubParagraph'}>
                            <p className={'subheading'}>{t(`PrivacyNoticeBlock2SubParagraph${index + 2}Title`)}</p>
                            <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock2SubParagraph${index + 2}Text`)}</p>
                        </div>
                    )}
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock3')}</h4>
                    {new Array(6).fill(1).map((i, index) =>
                        <div key={index} className={'PrivacyNoticeSubParagraph'}>
                            <p className={'subheading'}>{t(`PrivacyNoticeBlock3SubParagraph${index + 1}Title`)}</p>
                            <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock3SubParagraph${index + 1}Text`)}</p>
                        </div>
                    )}
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock4')}</h4>
                    {new Array(5).fill(1).map((i, index) =>
                        <div key={index} className={'PrivacyNoticeSubParagraph'}>
                            <p className={'subheading'}>{t(`PrivacyNoticeBlock4SubParagraph${index + 1}Title`)}</p>
                            <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock4SubParagraph${index + 1}Text`)}</p>
                        </div>
                    )}
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock5')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock5Text1')}<a href={'https://www.google.com/analytics/terms/de.html'} className={'notColorA'}>https://www.google.com/analytics/terms/de.html</a>{t('and')}<a href={'https://policies.google.com/?hl=de'} className={'notColorA'}>https://policies.google.com/?hl=de</a>{t('PrivacyNoticeBlock5Text2')}
                    </p>
                    <div className={'PrivacyNoticeSubParagraph PrivacyNoticeSubParagraphWithMarginTop'}>
                        <p className={'subheading'}>{t(`PrivacyNoticeBlock5SubParagraph1Title`)}</p>
                        <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock5SubParagraph1Text`)}
                            {/*<a href={'#'}*/}
                            {/*   onClick={() => UC_UI.showSecondLayer()}>{t('PrivacyNoticeBlock5SubParagraph1Link')}</a>*/}
                            {/*{t('PrivacyNoticeBlock5SubParagraph1TextPart2')}*/}
                        </p>
                    </div>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock7')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock7Text1')}<a className={'notColorA'} href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a>{t('PrivacyNoticeBlock7Text2')}</p>
                    <div className={'PrivacyNoticeSubParagraph PrivacyNoticeSubParagraphWithMarginTop'}>
                        <p className={'subheading'}>{t(`PrivacyNoticeBlock7SubParagraph1Title`)}</p>
                        <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock7SubParagraph1Text`)}</p>
                    </div>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock6')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock6Text1')}</p>
                    <div className={'PrivacyNoticeSubParagraph PrivacyNoticeSubParagraphWithMarginTop'}>
                        <p className={'subheading'}>{t(`PrivacyNoticeBlock6SubParagraph1Title`)}</p>
                        <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock6SubParagraph1Text`)}</p>
                    </div>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock8')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock8Text1')}</p>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock9')}</h4>
                    <div className={'PrivacyNoticeSubParagraph PrivacyNoticeSubParagraphWithMarginTop'}>
                        <p className={'subheading'}>{t(`PrivacyNoticeBlock9SubParagraph1Title`)}</p>
                        <p className={'PrivacyNoticeFormatedText'}>{t(`PrivacyNoticeBlock9SubParagraph1Text`)}</p>
                    </div>
                    <div className={'PrivacyNoticeSubParagraph PrivacyNoticeSubParagraphWithMarginTop'}>
                        <p className={'subheading'}>{t(`PrivacyNoticeBlock9SubParagraph2Title`)}</p>
                        <div className={'PrivacyNoticeEmailInfo'}>
                            <p>Visoplan GmbH </p>
                            <p>Tel.: +49 030 / 555 71 779</p>
                            <p>Mail: kontakt@visoplan.de</p>
                        </div>
                    </div>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock10')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock10Text1')}</p>
                </div>
                <div className={'PrivacyNoticeBlockInfo'}>
                    <h4>{t('PrivacyNoticeBlock11')}</h4>
                    <p className={'PrivacyNoticeFormatedText'}>{t('PrivacyNoticeBlock11Text1')}</p>
                    <div className={'PrivacyNoticeEmailInfo'}>
                        <p>Visoplan GmbH </p>
                        <p>Tel.: +49 030 / 555 71 779</p>
                        <p>Mail: kontakt@visoplan.de</p>
                    </div>
                </div>
                <p className={'FinishText'}>{t('PrivacyNoticeFinishText')}</p>

                <Footer/>
            </div>
        </>
    )
}
export default PrivacyNotice;