import React from 'react';

export function PageNotFound() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'calc(100vh - 200px)',
      }}
    >
      <h1
        style={{
          fontSize: '140px'
        }}
      >404</h1>
      <h2
        style={{
          fontSize: '28px',
          marginTop: '10px',
        }}
      >Page not found</h2>
    </div>
  );
}