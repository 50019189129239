import {useState, useRef, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router";
import { Link } from "react-router-dom";
import './index.css';
import {changeLanguage} from "../../utils/changeLanguage";
import {useOutsideClick} from '../../hooks/useOutsideClick';
import logo from '../../assets/Header/logo.svg';
import angleDown from '../../assets/Header/angledown.svg';
import languageIcon from '../../assets/Header/iconLanguage.svg';
import squareFour from '../../assets/Header/SquaresFour.svg';
import xSquare from '../../assets/Header/XSquare.svg';
import i18n from "../../i18n";
import ArrowRight from "../../assets/Header/ArrowRight";
import useDeviceDetect from '../../hooks/useDeviceDetect';
import Graphic from "../../assets/Header/Graphic";
import {workflows} from "../../assets/Header";
import Angle from "../../assets/TaskIssue/Angle";
import {socialMedias} from "../../assets/Footer";

const HeaderBigFeaturesMenu = ({
    navigateTo,
    navigate,
    setOpenFeatures,
}) => {
    const [featuresBigMenuHover, setMenuBigHover] = useState(100);
    const {t, i18n} = useTranslation();
    const origin = window.location?.origin;
    const currentLang = i18n.language;
    const listOfFeatures = [{
            link: '/product/bim-model-management', name: t('BIM'), description: t('BIMDescription'), color: '#20C1CD'
        }, {
            link: '/product/dms', name: t('DMSHeader'), description: t('DMSDescription'), color: '#AA007B'

        }, {
            link: '/product/task-and-issue-management', name: t('TaskAndIssue'), description: t('TaskIssueDescription'), color: '#004D95'
        }, {
            link: '/product/information-management', name: t('InfoManagement'), description: t('InfoManagementDescription'), color: '#4B6FFC'
        }, {
            link: '/product/plan-management',
            name: t('PlanManagement'),
            description: t('PlanManagementDescription'),
            color: '#4B6FFC'

        }, {
            link: '/product/ifc-viewer', name: t('IFC'), description: t('IFCDescription'), color: '#4B6FFC'
        }, {
            link: '/product/cloud-infrastructure-and-security', name: t('CloudInfrastructure'), description: t('CloudDescription'), color: '#4B6FFC'
        },
    ];

    return (
        <div className={'HeaderBigFeaturesMenu'} onClick={(e) => e.stopPropagation()}>
            <div className={'MenuWrappers'}>
                <div className={'FeaturesMenuColumn'}>
                    <h3>FEATURES</h3>
                    <ul>
                        {listOfFeatures.map((i, index) => {
                            return (
                                <li key={`${currentLang}${i.link}`}
                                    style={{background: `${index === featuresBigMenuHover ? `${i.color}` : 'inherit'}`, border:`${index === featuresBigMenuHover ? `none` : ''}`}}
                                    onMouseOver={() => {
                                        setMenuBigHover(index)
                                    }}
                                    className={`${index === featuresBigMenuHover ? 'FeaturesMenuColumnActive' : ''}`}
                                >
                                        <div className={'elementFeaturesMenuBlock'}>
                                            <a href={`${origin}/${currentLang}${i.link}`}>
                                                <span>{i.name}</span>
                                                {index === featuresBigMenuHover && (
                                                <p>{i.description}</p>)}
                                            </a>
                                        </div>
                                        <ArrowRight rotate={index === featuresBigMenuHover} />
                                </li>)
                        })}
                    </ul>
                </div>
                <div className={'FeaturesGraphic'}>
                    <Graphic hoverIndex={featuresBigMenuHover}/>
                </div>
                <div className={'WorkflowMenuColumn'}>
                    <h3>WORKFLOWS</h3>
                    <ul>
                        {workflows.map((i) =>
                            <li
                                key={`${currentLang}/workflows${i.link}`}
                            >
                                <a href={`${origin}/${currentLang}/workflows${i.link}`}>
                                    <img
                                        src={i.src} alt={i.title}
                                        onError={({currentTarget}) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = i.srcFallback;
                                        }}
                                    />
                                    {t(i.title)}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export const generateUrl = (lang, currentUrl) => {
    let url = currentUrl.split('/');
    url.splice(1, 1, lang);
    url = url.join('/')
    return url;
}

const Header = () => {
    const bigFeaturesMenuRef = useRef(null);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [openFeatures, setOpenFeatures] = useState(false);
    const [openMobileFeatures, setOpenMobileFeatures] = useState(false);
    const [openMobileWorkflow, setOpenMobileWorkflow] = useState(false);
    const isMobile = useDeviceDetect();
    useOutsideClick(bigFeaturesMenuRef, () => setOpenFeatures(false));
    const {t} = useTranslation();
    const navigate = useNavigate();
    const language = i18n.language;
    const origin = window.location?.origin;
    const navigateTo = (link) => {
        navigate(link);
        window.scrollTo(0, 0);
    }
    const loc = useLocation();
    useEffect(() => {
        const isLng = loc.pathname.split('/')[1];
        if (language !== isLng) {
            changeLanguage(isLng);
        }
    }, [loc.pathname]) 
    return (
        <div className={'Header'}>
            <img
                style={{cursor: 'pointer'}}
                onClick={() => navigateTo(`${language}`)}
                src={logo} alt={'logo'}
            />
            {
                !isMobile ? (
                    <ul className={'NavMenu'} ref={bigFeaturesMenuRef}>
                        <li className={'NavMenuElement'} onClick={() => setOpenFeatures(!openFeatures)}>
                            {t('header_features_title')}
                            <img src={angleDown} alt={'angleDown'}/>
                            {openFeatures
                                ? <HeaderBigFeaturesMenu
                                    navigate={navigate}
                                    navigateTo={navigateTo}
                                    openFeatures={openFeatures}
                                    setOpenFeatures={setOpenFeatures}
                                    bigFeaturesMenuRef={bigFeaturesMenuRef}
                                />
                                : null}
                        </li>
                        <li className={'NavMenuElement'} onClick={() => setOpenFeatures(!openFeatures)}>
                            {t('header_workflow_title')}
                            <img src={angleDown} alt={'angleDown'}/>
                        </li>
                        <li className={'NavMenuElement'}>
                            <a href='https://www.visoplan.de/blog?lang=english'>
                                {t('header_blog_title')}
                            </a>
                        </li>
                        <li className={'NavMenuElement'} onClick={() =>
                         {
                            setOpenFeatures(false)
                           }}>
                            <a href={`${origin}/${language}/pricing`}>
                                {t('Pricing')}
                            </a>
                        </li>
                    </ul>
                ) : (
                    <>
                        {
                            openFeatures && <div className={'HeaderDropdownMenuMobile'} ref={bigFeaturesMenuRef}>
                        <ul>
                            <label
                                onClick={() => setOpenMobileFeatures(!openMobileFeatures)}>{t('header_features_title')}<Angle
                                positionAbsolute={false} width={40} height={40}
                                rotatePosition={openMobileFeatures ? '180' : '0'} color={'#FFFFFF'}
                                header={true}
                            /></label>
                            {openMobileFeatures &&
                                <>
                                    <li>
                                        <a href={`${origin}/${language}/product/bim-model-management`}>
                                            {t('BIMModelManagement')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/dms`}>
                                            {t('DocumentManagement')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/task-and-issue-management`}>
                                            {t('TaskIssueManagement')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/information-management`}>
                                            {t('InformationManagement')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/plan-management`}>
                                            {t('PlanManagement')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/ifc-viewer`}>
                                            {t('IFC')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${origin}/${language}/product/cloud-infrastructure-and-security`}>
                                            {t('CloudInfrastructure')}
                                        </a>
                                    </li>
                                </>}
                        </ul>
                        <ul>
                            <label
                                onClick={() => setOpenMobileWorkflow(!openMobileWorkflow)}> {t('header_workflow_title')}<Angle
                                positionAbsolute={false} width={40} height={40}
                                header={true}
                                rotatePosition={openMobileWorkflow ? '180' : '0'} color={'#FFFFFF'}/></label>
                            {openMobileWorkflow && <>
                                {workflows.map((i) => (
                                    <li
                                        onClick={() => {
                                            setOpenFeatures(false);
                                        }}
                                    >
                                        <a href={`${origin}/${language}/workflows${i.link}`}>
                                            {t(i.title)}
                                        </a>
                                    </li>
                                ))}
                            </>}
                        </ul>
                        <div className={'HeaderFlexContainerList'}>
                            <ul>
                                <li onClick={() => {
                                    setOpenFeatures(false)
                                }
                                }>
                                    <a href={`${origin}/${language}/pricing`}>
                                        {t('Pricing')}
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.visoplan.de/blog?lang=english'>
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a href='https://help.visoplan.de/'>
                                        {t('Support')}
                                    </a>
                                </li>
                                <li onClick={() => {
                                    setOpenFeatures(false)
                                }
                                }>
                                    <a href={`${origin}/${language}/about-us`}>
                                        {t('AboutUs')}
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <label onClick={() => navigateTo(`${language}/careers`)}>{t('CareerJob')}</label>
                                <li onClick={() => {
                                    setOpenFeatures(false)
                                }
                                }>
                                    <a href={`${origin}/${language}/imprint`}>
                                        {t('Imprint')}
                                    </a>
                                </li>
                                <li onClick={() => {
                                    setOpenFeatures(false)
                                }
                                }>
                                    <a href={`${origin}/${language}/privacy-notice`}>
                                        {t('PrivacyNotice')}
                                    </a>
                                </li>
                                <li>
                                    <a href={'https://docs.visoplan.de/AGB_Visoplan.pdf'}>
                                        {t('Terms')}
                                    </a>
                                    
                                </li>
                            </ul>
                        </div>
                        <div className={'HeaderSocialMediaContainer'}>
                            {socialMedias.map((i, key) => <a href={i.link}>
                                <img src={i.src} key={key}/>
                            </a>)}
                        </div>
                        <div className={'HeaderDropdownMenuMobilelanguage'}>
                            <div onClick={() => {
                                changeLanguage('en');
                                navigate(`../${generateUrl('en', document.location.pathname)}`, { replace: true });
                            }}
                                style={{color: `${language !== 'de' ? '#FFBE28' : ''}`}}>EN
                            </div>
                            <div onClick={() => {
                                changeLanguage('de');
                                navigate(`../${generateUrl('de', document.location.pathname)}`, { replace: true });
                            }}
                                style={{color: `${language === 'de' ? '#FFBE28' : ''}`}}>DE
                            </div>
                        </div>
                            </div>
                        }
                    </>
                )
            }
            <div className={'Header_Login_Part'}>
                <div className={'line'}/>
                <div className={'LanguageWrapper'}>
                    <ul className={`${!openLanguage ? 'SelectLanguage' : 'SelectLanguageActive'}`}>
                        <li onClick={() => {
                            changeLanguage('en');
                            navigate(`../${generateUrl('en', document.location.pathname)}`, { replace: true });
                            setOpenLanguage(!openLanguage)
                        }}>{t('English')}
                        </li>
                        <li onClick={() => {
                            changeLanguage('de');
                            navigate(`../${generateUrl('de', document.location.pathname)}`, { replace: true });
                            setOpenLanguage(!openLanguage)
                        }}>{t('German')}
                        </li>
                    </ul>
                    <img alt={'language'}
                        onClick={() => setOpenLanguage(!openLanguage)}
                        src={languageIcon}
                    />
                </div>
                <button><a href={'https://web.visoplan.de/login'}>{t('login')}</a></button>
                <img
                    onClick={() => setOpenFeatures(!openFeatures)}
                    className={'Header_Login_image'}
                    alt={'Header_Login_image'}
                    src={openFeatures ? xSquare : squareFour}/>
            </div>

        </div>)
}
export default Header;