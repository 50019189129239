import image1 from './Architects.webp';
import image2 from './Civil Engineer.webp';
import image3 from './Project Developers.webp';
import image4 from './General Contractors.webp';
import image5 from './BIM Managers.webp';
import image6 from './Project Controllers.webp';
import ArrowDown from './ArrowDown.svg';
import image7 from './Builders.webp';
import image1Fallback from './Architects.png';
import image2Fallback from './CivilEngineer.png';
import image3Fallback from './ProjectDevelopers.png';
import image4Fallback from './GeneralContractors.png';
import image5Fallback from './Bim.png';
import image6Fallback from './ProjectControllers.png';
import image7Fallback from './Builders.png';
import ArrowRight from './ArrowRight.svg';

export const workflows = [
    {
        title: 'Architects',
        link: '/architects',
        src: image1,
        srcFallback:image1Fallback
    },
    {
        title: 'CivilEngineers',
        src: image2,
        link: '/architects',
        srcFallback:image2Fallback
    },
    {
        title: 'ProjectDevelopers',
        link: '/builders',
        src: image3,
        srcFallback:image3Fallback
    },
    {
        title: 'GeneralContractors',
        link: '/general-contractors',
        src: image4,
        srcFallback:image4Fallback
    },
    {
        title: 'BimManager',
        link: '/bim-managers',
        src: image5,
        srcFallback:image5Fallback
    },
    {
        title: 'ProjectControllers',
        link: '/bim-managers',
        src: image6,
        srcFallback:image6Fallback
    },
    {
        title: 'Builders',
        link: '/builders',
        src: image7,
        srcFallback:image7Fallback
    },
];

export {ArrowDown, ArrowRight};