import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import DeuTranslation from './locales/deu.json';
import EngTranslation from './locales/eng.json';

const resources = {
    en: {
        translation: EngTranslation
    },
    de: {
        translation: DeuTranslation
    }
};

const htmlLang = document.documentElement.lang;

const Languages = ['en', 'de'];

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: htmlLang || 'de',
        lng: htmlLang || localStorage.getItem('i18nextLng') || 'de',
        whiteList: Languages,
        debug: false,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;