import React, {useEffect, useState, lazy, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import i18n from '../../i18n';
import {useNavigate, useParams} from 'react-router';
import useDeviceDetect from '../../hooks/useDeviceDetect';

import AnimatedScroll from '../../components/AnimatedScroll/AnimatedScroll';

import {workflows} from '../../assets/Header/index';
import {addedValuesImgs, bannersImg, sliderImgs} from '../../assets/Workflow';
import {addedValues, sliderTitles, workflowsInfo} from './helpers';
import Check from '../../assets/Workflow/Check.svg';
import { useSliderTransitionWidth } from './useSliderTransitionWidth';
import './index.css';

const WorkflowForehead = lazy(() =>import("./WorkflowForehead"));
const WorkflowsSection = lazy(() =>import("./WorkflowsSection"));
const ArchitectsAndEngineers = lazy(() =>import("./ArchitectsAndEngineers"));
const BimManagersAndProjectControllers = lazy(() =>import("./BimManagersAndProjectControllers"));
const Footer = lazy(() =>import("../../components/Footer"));
const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));

const Workflow = () => {
  const mobile = useDeviceDetect();
  const width1030 = useDeviceDetect(1030);
  const width1000 = useDeviceDetect(1000);
  const listOffsetForeheadForDesktop = useSliderTransitionWidth();

  const { workflowName } = useParams();
  const navigate = useNavigate();
  const language = i18n.language;
  const { t } = useTranslation();
  const workflowCardsList = [
    {
      name: 'ArchitectsAndEngineers',
      title: <>{t('ArchitectsAnd')} <br /> {t('Engineers')}</>,
      path: '/workflows/architects',
      imgs: [...workflows.filter((img) => img.title === 'Architects' || img.title === 'CivilEngineers')],
    },
    {
      name: 'BimManagersAndProjectControllers',
      title: <>{t('BimManagersAnd')} <br /> {t('ProjectControllers')}</>,
      path: '/workflows/bim-managers',
      imgs: [...workflows.filter((img) => img.title === 'BimManager' || img.title === 'ProjectControllers')],
    },
    {
      name: 'GeneralContractors',
      title: t('GeneralContractors'),
      path: '/workflows/general-contractors',
      imgs: [...workflows.filter((img) => img.title === 'GeneralContractors')],
    },
    {
      name: 'BuildersAndProjectDevelopers',
      title: <>{t('BuildersAnd')} <br /> {t('ProjectDevelopers')}</>,
      path: '/workflows/builders',
      imgs: [...workflows.filter((img) => img.title === 'Builders' || img.title === 'ProjectDevelopers').reverse()],
    },
  ];

  const [activeCard, setActiveCard] = useState(workflowCardsList[0]);
  const [currentWorkflow, setCurrentWorkflow] = useState({
    title: workflowsInfo.ArchitectsAndEngineers.options[0],
    description: workflowsInfo.ArchitectsAndEngineers.PropertySets,
  });

  const [carouselPhotoForeheadIndex, setCarouselPhotoForeheadIndex] = useState(1);
  const [offSetForehead, setOffsetForehead] = useState(mobile ? 0 : listOffsetForeheadForDesktop[0]);
  const [sliderUp, setSliderUp] = useState(true);
  const [slideOrderUP, setSlideOrderUP] = useState(false);

  const listOffsetForeheadForMobile = [0, 0, 0, 0, 0, 0, 0];
  useEffect(() => {
    switch (workflowName) {
      case 'architects':
        setActiveCard(workflowCardsList[0]);
        setCurrentWorkflow({
          title: workflowsInfo[workflowCardsList[0].name].options[0],
          description: Object.values(workflowsInfo[workflowCardsList[0].name])[0],
        });
        break;
      case 'bim-managers':
        setActiveCard(workflowCardsList[1]);
        setCurrentWorkflow({
          title: workflowsInfo[workflowCardsList[1].name].options[0],
          description: Object.values(workflowsInfo[workflowCardsList[1].name])[0],
        });
        setCarouselPhotoForeheadIndex(1);
        setOffsetForehead(mobile ? listOffsetForeheadForMobile[0] : listOffsetForeheadForDesktop[0]);
        break;
      case 'general-contractors':
        setActiveCard(workflowCardsList[2]);
        setCurrentWorkflow({
          title: workflowsInfo[workflowCardsList[2].name].options[0],
          description: Object.values(workflowsInfo[workflowCardsList[2].name])[0],
        });
        setCarouselPhotoForeheadIndex(1);
        setOffsetForehead(mobile ? listOffsetForeheadForMobile[0] : listOffsetForeheadForDesktop[0]);
        break;
      case 'builders':
        setActiveCard(workflowCardsList[3]);
        setCurrentWorkflow({
          title: workflowsInfo[workflowCardsList[3].name].options[0],
          description: Object.values(workflowsInfo[workflowCardsList[3].name])[0],
        });
        setCarouselPhotoForeheadIndex(1);
        setOffsetForehead(mobile ? listOffsetForeheadForMobile[0] : listOffsetForeheadForDesktop[0]);
        break;
      default:
        break;
    }
  }, [workflowName]);

  const getBannerImg = () => {
    if (language === 'en' && mobile) return bannersImg.BuildersAndProjectDevelopersBannerMobEN;
    if (language === 'de' && mobile) return bannersImg.BuildersAndProjectDevelopersBannerMobDE;
    if (language === 'en' && !mobile) return bannersImg.BuildersAndProjectDevelopersBannerEN;
    if (language === 'de' && !mobile) return bannersImg.BuildersAndProjectDevelopersBannerDE;
    return ''
  };

  const displaySliderImage = (index) => {
    if (
      (carouselPhotoForeheadIndex === index && !mobile && !width1030) ||
      carouselPhotoForeheadIndex === index + 1 ||
      (carouselPhotoForeheadIndex === index + 2 && !mobile && !width1030)
    ) {
      return 'block';
    }
    return 'none';
  };

  return (
    <div>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t('WorflowsMetaTitle')}</title>
        <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/workflows`} />
        <link rel="alternate" hreflang="en" href={`https://www.visoplan.de/en/workflows/${workflowName ? workflowName : ""}`} />
        <link rel="alternate" hreflang="de" href={`https://www.visoplan.de/de/workflows/${workflowName ? workflowName : ""}`} />
        <meta name="description" content={t('WorflowsMetaDescription')} />
      </Helmet>
      <Suspense fallback={() => null}>
        <WorkflowForehead
          mobile={mobile}
          language={language}
        />
        <WorkflowsSection
          mobile={mobile}
          language={language}
          navigate={navigate}
          width1000={width1000}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          workflowsInfo={workflowsInfo}
          currentWorkflow={currentWorkflow}
          setOffsetForehead={setOffsetForehead}
          workflowCardsList={workflowCardsList}
          setCurrentWorkflow={setCurrentWorkflow}
          listOffsetForeheadForMobile={listOffsetForeheadForMobile}
          listOffsetForeheadForDesktop={listOffsetForeheadForDesktop}
          setCarouselPhotoForeheadIndex={setCarouselPhotoForeheadIndex}
        />
      </Suspense>
      {
        activeCard.name === 'ArchitectsAndEngineers'
          ? <ArchitectsAndEngineers
              mobile={mobile}
              language={language}
            />
          : null
      }
      <div className={'AddedValues'}>
        <div className={'AddedValuesDescriptions'}>
          <h1>{t('AddedValues')}</h1>
          {addedValues[activeCard.name].map((item, index) => (
            <div key={item + index}>
              <img src={Check} alt={item}
                   onError={({currentTarget}) => {
                     currentTarget.onerror = null;
                     currentTarget.src = item.srcFallback;
                   }}
              />
              {t(`${item}`)}
            </div>
          ))}
        </div>
        <div className={'AddedValuesImgs'}>
          {addedValuesImgs[activeCard.name].map((item, index) => (
            <img
                src={item.src}
                key={`img-${index}`}
                alt={`img-${index}`}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null;
                  currentTarget.src = item.srcFallback;
                }}
                className={`${addedValuesImgs[activeCard.name].length < 2 ? 'ValuesImg ' : ''}${
                    activeCard.name === 'ArchitectsAndEngineers' ? `ArchitectsAndEngineersValues${index + 1}` : null
                }`}
            />
          ))}
        </div>
      </div>
      <Suspense fallback={() => null}>
        {activeCard.name === 'BimManagersAndProjectControllers'
            ? <BimManagersAndProjectControllers
                language={language}
              />
            : null
        }
      </Suspense>
      {activeCard.name === 'BuildersAndProjectDevelopers' ? (
          <div className={'WorkflowAdvertising p-80'}>
            <div className={'AdvertisingTitle'}>
              <h3>{t('WorkflowVisoplanSubTitle')}</h3>
              <h1>{t('WorkflowVisoplanTitle')}</h1>
            </div>
            <img
                src={getBannerImg()}
                alt="BuildersAndProjectDevelopersBanner"
                className={'BuildersAndProjectDevelopersBanner'}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null;
                  currentTarget.src =mobile ? bannersImg.BuildersAndProjectDevelopersBannerFallback : bannersImg.BuildersAndProjectDevelopersBannerFallbackMob;
                }}
            />
          </div>
      ) : null}
      {activeCard.name !== 'ArchitectsAndEngineers' ? (
          <div className={'WorkflowSlider DmsForeheadWithCarousel ForeheadWithCarousel'}>
            <div className={'PhotosCarousel'}>
            {sliderImgs[activeCard.name].map((img, index) => (
              carouselPhotoForeheadIndex || listOffsetForeheadForMobile) === index + 1 ? (
                <h1 key={index}>{t(`${sliderTitles[activeCard.name][index]}`)}</h1>
              ) : null
            )}
              <div
                className={'PhotosCarouselWindow'}
                style={{ transform: `translateX(${offSetForehead}${mobile ? '%' : 'vw'})` }}
              >
              {sliderImgs[activeCard.name].map((img, index) => (
                <div
                  key={`PhotosCarouseItem-${index}`}
                  style={{
                    display: mobile ? displaySliderImage(index) : 'block'
                  }}
                  className='PhotosCarouseItem'
                >
                  <div
                    onClick={() => {
                      setCarouselPhotoForeheadIndex(index + 1);
                      setOffsetForehead(
                          mobile
                            ? listOffsetForeheadForMobile[index]
                            : listOffsetForeheadForDesktop[index]
                          );
                      if (carouselPhotoForeheadIndex === sliderImgs[activeCard.name].length - 1) {
                        return setSliderUp(true);
                      }
                    }}
                    className={
                      carouselPhotoForeheadIndex === index + 1 ? 'DmsPhotoCarouselActive' : 'DmsPhotoCarouselInActive'
                    }
                  >
                    <img src={img.src} alt={`img-${index}`}
                         onError={({currentTarget}) => {
                           currentTarget.onerror = null;
                           currentTarget.src = img.srcFallback;
                         }}
                    />
                  </div>
                </div>
              ))}
              </div>
          </div>
          <div className={'SliderIndicators'} style={{ transform: `translateY(${0}%)` }}>
            {sliderImgs[activeCard.name].map((img, index) => (
              <AnimatedScroll
                isWorkflow={true}
                key={index}
                open={carouselPhotoForeheadIndex === index + 1}
                clickFunction={() => {
                  setCarouselPhotoForeheadIndex(index + 1);
                  setOffsetForehead(
                    mobile
                    ? listOffsetForeheadForMobile[index]
                    : listOffsetForeheadForDesktop[index]
                  );
                  setSliderUp(true);
                }}
                sliderUp={sliderUp}
                finishFunction={() => {
                  if (sliderImgs[activeCard.name].length === index + 1) {
                    return (
                      setCarouselPhotoForeheadIndex(index),
                      setOffsetForehead(
                        mobile
                          ? listOffsetForeheadForMobile[index - 1]
                          : listOffsetForeheadForDesktop[index - 1]
                      ),
                      setSlideOrderUP(true)
                    );
                  }
                  if (index === 0) {
                    return (
                      setSlideOrderUP(false),
                      setCarouselPhotoForeheadIndex(index + 2),
                      setOffsetForehead(
                        mobile
                          ? listOffsetForeheadForMobile[index + 1]
                          : listOffsetForeheadForDesktop[index + 1]
                      )
                    );
                  }
                  if (slideOrderUP) {
                    setCarouselPhotoForeheadIndex(index);
                    setOffsetForehead(
                      mobile
                        ? listOffsetForeheadForMobile[index - 1]
                        : listOffsetForeheadForDesktop[index - 1]
                    );
                  } else {
                    setCarouselPhotoForeheadIndex(index + 2);
                    setOffsetForehead(
                      mobile
                        ? listOffsetForeheadForMobile[index + 1]
                        : listOffsetForeheadForDesktop[index + 1]
                    );
                  }
                  if (carouselPhotoForeheadIndex === sliderImgs[activeCard.name].length - 1) return setSliderUp(true);
                  if (carouselPhotoForeheadIndex === 2) return setSliderUp(false);
                }}
              />
            ))}
          </div>
        </div>
      ) : null}

      <Suspense fallback={() => null}>
        <ContactUsForm page={'BIM'} />
        <Footer page={'BIM'} />
      </Suspense>
    </div>
  );
};
export default Workflow;
