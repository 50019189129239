import React from 'react';
import features100 from './features100.svg';
import features0 from './features0.svg';
import features1 from './features1.svg';
import features2 from './features2.svg';
import features3 from './features3.svg';
import features4 from './features4.svg';
import features5 from './features5.svg';
import features6 from './features6.svg';

const Graphic = ({hoverIndex}) => {
    if (hoverIndex === 0) {
        return (
            <img src={features0} alt={'features0'}/>)
    }
    if (hoverIndex === 1) {
        return (
            <img src={features1} alt={'features1'}/>
        )
    }
    if (hoverIndex === 2) {
        return (
            <img src={features2} alt={'features2'}/>
        )
    }
    if (hoverIndex === 3) {
        return (
            <img src={features3} alt={'features3'}/>
        )
    }
    if (hoverIndex === 4) {
        return (
            <img src={features4} alt={'features4'}/>
        )
    }
    if (hoverIndex === 5) {
        return (
            <img src={features5} alt={'features5'}/>
        )
    }
    if (hoverIndex === 6) {
        return (
            <img src={features6} alt={'features6'}/>
        )
    }
    return (
        <img src={features100} alt={'features100'}/>
    )
}
export default Graphic;