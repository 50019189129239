import {useState} from 'react';
import './index.css';
import logo from '../../assets/Footer/logo.svg';
import {useTranslation} from "react-i18next";
import {socialMedias} from "../../assets/Footer";
import i18n from "../../i18n";
import {useNavigate} from "react-router-dom";
import {changeLanguage} from "../../utils/changeLanguage";
import {sendMessage} from "../ContactUsContainer";
import {generateUrl} from '../Header/index';

const applications = [
    {
        path: '/product/bim-model-management',
        title: 'BIMModelManagement'
    },
    {
        path: '/product/dms',
        title: 'DMS'
    },
    {
        path: '/product/task-and-issue-management',
        title: 'TaskAndIssue'
    },
    {
        path: '/product/information-management',
        title: 'InfoManagement'
    },
    {
        path: '/product/plan-management',
        title: 'PlanManagement'
    },
    {
        path: '/product/ifc-viewer',
        title: 'IFC'
    },
    {
        path: '/product/cloud-infrastructure-and-security',
        title: 'CloudInfrastructure'
    },
    {
        path: '/pricing',
        title: 'Pricing'
    },
];

const company = [
    {
        path: '/about-us',
        title: 'AboutUs'
    },
    {
        path: '/careers',
        title: 'CareerJob'
    },
    {
        path: '/imprint',
        title: 'Imprint'
    },
    {
        path: '/privacy-notice',
        title: 'PrivacyNotice'
    }
];

export default function Footer({page}) {
    const {t} = useTranslation();
    const language = i18n.language;
    const navigate = useNavigate();
    const [email,setEmail]=useState();
    const origin = window.location?.origin;

    const navigateTo = (link) => {
        navigate(`../${link}`, { replace: true });
        window.scrollTo(0, 0);
    }

    return (
        <div className={'Container'}>
            <div className={'LogonWrapper'}>
                <img src={logo} alt='logo' />
            </div>
            <div className={'FooterBody'}>
                <div className={'FooterColumns'}>
                    <ul style={{width: '40%'}}>
                        <label>{t('Application')}</label>
                        {
                            applications.map(({ path , title }) => (
                                <li className='route' key={path}>
                                    <a href={`${origin}/${language}${path}`}>
                                        {t(title)}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                    <div style={{
                        width: '26%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <ul>
                            <label>{t('Knowledge')}</label>
                            <li className='route' key='visoplan.de'>
                                <a href='https://www.visoplan.de/blog?lang=english'>
                                    {t('Blog')}
                                </a>
                            </li>
                            <li className='route' key='help.visoplan.de/'>
                                <a href='https://help.visoplan.de/'>
                                    {t('Support')}
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <label>{t('language')}</label>
                            <li onClick={() => {
                                changeLanguage('de');
                                navigate(`../${generateUrl('de', document.location.pathname)}`, { replace: true });
                            }}
                                key='German'
                                style={{
                                color: `${language === 'de' ? '#FFBE28' : ''}`,
                                cursor: 'pointer'
                            }}>{t('German')}</li>
                            <li key='English'
                                onClick={() => {
                                changeLanguage('en');
                                navigate(`../${generateUrl('en', document.location.pathname)}`, { replace: true });
                            }} style={{
                                color: `${language !== 'de' ? '#FFBE28' : ''}`,
                                cursor: 'pointer'
                            }}>{t('English')}</li>
                        </ul>
                    </div>
                    <ul>
                        <label>{t('Company')}</label>
                        {
                            company.map(({ path , title }) => (
                                <li className='route' key={path}>
                                    <a href={`${origin}/${language}${path}`}>
                                        {t(title)}
                                    </a>
                                </li>
                            ))
                        }
                        <li className='route' key='docs.visoplan.de/AGB_Visoplan.pdf'>
                            <a href='https://docs.visoplan.de/AGB_Visoplan.pdf'>
                                {t('Terms')}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={'FooterContactsColumn'}>
                    <p> {t('FooterFormLabel')}</p>
                    <form autoComplete="on" className={'FooterContactsForm'}>
                        <input autoComplete="email" id='email' name='email' onChange={(e)=>setEmail(e.target.value)} placeholder={t('emailFooter')} type='email' />
                        <button type='submit'
                            onClick={(e) => {
                                e.preventDefault();
                                sendMessage({email,name:'_',text:'_'},0,'REQUEST NEWSLETTER/',page)}
                            }
                        >{t('submitFooter')}</button>
                    </form>
                    <ul>
                        <label>{t('language')}</label>
                        <li onClick={() => {
                            changeLanguage('de');
                            navigate(`../${generateUrl('de', document.location.pathname)}`, { replace: true });
                        }} style={{
                            color: `${language === 'de' ? '#FFBE28' : ''}`,
                            cursor: 'pointer'
                        }}>{t('German')}</li>
                        <li onClick={() => {
                            changeLanguage('en');
                            navigate(`../${generateUrl('en', document.location.pathname)}`, { replace: true });
                        }} style={{
                            color: `${language !== 'de' ? '#FFBE28' : ''}`,
                            cursor: 'pointer'
                        }}>{t('English')}</li>
                    </ul>
                    <div className={'SocialMediaWrapper'}>
                        <p style={{textTransform: 'uppercase'}}>{t('FollowUs')}</p>
                        <div className={'SocialMediaList'}>
                            {socialMedias.map((i, key) => <a style={{cursor: 'pointer'}} href={i.link} key={i.link + key}>
                                <img src={i.src} alt={i.alt} key={key}/>
                            </a>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}