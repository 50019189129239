import {useEffect, useState, lazy, Suspense} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet';

import useDeviceDetect from "../../hooks/useDeviceDetect";
import useScrollPosition from "../../hooks/useScrollPosition";
import i18n from "../../i18n";
import './index.css';

import ForeheadForm from "../../components/ForeheadForms";
import {
    BackgroundForeheadSmallScreen,
    ForeheadGraphic,
    ForeheadGraphicFallback,
    ForeheadGraphicSmallScreen,
    ForeheadGraphicSmallScreenFallback,
} from "../../assets/IFC";

const IFCWebAsDesktopVersion = lazy(() =>import("./IFCWebAsDesktopVersion"));
const AnimatedBlock = lazy(() =>import("./AnimatedContentBlock"));
const AnimatedContentBlock = lazy(() =>import("./AnimatedContentBlock2"));
const IFCPowerVisoplan = lazy(() =>import("./IFCPowerVisoplan"));
const Footer = lazy(() =>import("../../components/Footer"));
const ContactUsForm = lazy(() =>import("../../components/ContactUsContainer"));
const GetStartedToday = lazy(() =>import("../Homepage/GetStartedToday"));

const IFC = () => {
    const {t} = useTranslation();
    const mobile = useDeviceDetect(968);
    const scrollPosition = useScrollPosition();
    const [listOfPowerVisoplan, setlistOfPowerVisoplan] = useState([false, false, false, false])

    const [animationStarted, setAnimationStarted] = useState(false)

    const setActiveElementList = (id, value) => {
        setlistOfPowerVisoplan(listOfPowerVisoplan.map((i, index) => id === index ? value : false
        ));
    }

    useEffect(() => {
        if ((window.pageYOffset > 800 && window.pageYOffset < 1200) && !animationStarted) {
            setAnimationStarted(true);
            setActiveElementList(0, true);
        }
    }, [scrollPosition])

    return (
        <div>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('IFCViewerMetaTitle')}</title>
                <link rel="canonical" href={`https://www.visoplan.de/${i18n.language}/product/ifc-viewer`} />
                <link rel="alternate" hreflang="en" href="https://www.visoplan.de/en/product/ifc-viewer" />
                <link rel="alternate" hreflang="de" href="https://www.visoplan.de/de/product/ifc-viewer" />
                <meta name="description" content={t('IFCViewerMetaDescription')} />
            </Helmet>
            <div className="IFCForehead">
                <div className="IFCForeheadData">
                    <h1>{t('IFCForeheadTitle')}</h1>
                    <p className={'subheading'}>{t('IFCForeheadSubTitle')}</p>
                    <form  autoComplete="on" className={'ForeheadForm IFCForeheadForm'}>
                        <label>
                            {t('ForeheadFormTitle')}
                        </label>
                        <ForeheadForm page={'IFC'} clName='BlueFocus'/>
                    </form>
                </div>
                <div className="IFCForeheadIllustration">
                    <div className={'IFCBACK'}/>
                    <img alt={'BackgroundForeheadSmallScreen'}
                         className={'IFCForeheadIllustrationBackgroundSmallScreen'}
                         src={BackgroundForeheadSmallScreen}/>
                    <img alt={'ForeheadGraphicSmallScreen'} className={'IFCForeheadIllustrationGraphicSmallScreen'}
                         src={ForeheadGraphicSmallScreen}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = ForeheadGraphicSmallScreenFallback;
                         }}
                    />
                    <img alt={'ForeheadGraphic'} className={'IFCForeheadIllustrationGraphic'} src={ForeheadGraphic}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = ForeheadGraphicFallback;
                         }}
                    />
                </div>
            </div>
            <Suspense fallback={() => null}>
                <IFCPowerVisoplan />
                <AnimatedBlock mobile={mobile} scrollPosition={scrollPosition} />
                <GetStartedToday page={'IFC'} />
                <AnimatedContentBlock mobile={mobile} scrollPosition={scrollPosition} />
                <IFCWebAsDesktopVersion listOfPowerVisoplan={listOfPowerVisoplan} setActiveElementList={setActiveElementList} />
                <ContactUsForm page={'IFC'} />
                <Footer/>
            </Suspense>
        </div>
    )
}
export default IFC