import React from 'react';

const Angle = ({color = '#5A667B', rotatePosition = '0', positionAbsolute = true, width = 40, height = 40,header=false}) => {
    return (
        <svg style={{
            transform: `rotate(${rotatePosition}deg)`,
            position: `${positionAbsolute ? 'absolute' : 'relative'}`,
            right: '0',
            top: `${header?rotatePosition === '180' ? '3px' : '-4px':rotatePosition === '180' ? '-3px' : '-7px'}`
        }} width={width} height={height}
             viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.5L12 15.5L9 12.5" stroke={color} strokeLinecap="square"/>
        </svg>
    )
}
export default Angle;