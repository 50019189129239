import {Suspense, lazy} from 'react';
import {BrowserRouter, Route, Routes, Navigate, useLocation, Redirect} from "react-router-dom";
import {I18nextProvider, useTranslation} from "react-i18next";

import i18n from "./i18n";
import {Homepage} from "./Pages";
import './App.css'
import { LayoutWrapper } from './components/Layout/index';
import { generateUrl } from './components/Header/index';
import { PageNotFound } from './Pages/NotFound';

const BIM = lazy(() => import("./Pages/BIM/index"));
const Career = lazy(() => import("./Pages/Career/index"));
const DMS = lazy(() => import("./Pages/DMS/index"));
const IFC = lazy(() => import("./Pages/IFC/index"));
const Imprint = lazy(() => import("./Pages/Imprint/index"));
const InfoManagement = lazy(() => import("./Pages/InfoManagement/index"));
const Pricing = lazy(() => import("./Pages/Pricing/index"));
const PrivacyNotice = lazy(() => import("./Pages/PrivacyNotes/index"));
const TaskIssue = lazy(() => import("./Pages/TaskIsuue/index"));
const Workflow = lazy(() => import("./Pages/Workflow/index"));
const CIS = lazy(() => import("./Pages/CloudInfrastructureAndSecurity/index"));
const PlanManagement = lazy(() => import("./Pages/PlanManagement/index"));
const AboutUs = lazy(() => import("./Pages/AboutUs/index"));

const routes = [
    {
        path: '/:lang',
        Component: Homepage
    },
    {
        path: '/:lang/product/task-and-issue-management',
        Component: TaskIssue
    },
    {
        path: '/:lang/product/ifc-viewer',
        Component: IFC
    },
    {
        path: '/:lang/product/dms',
        Component: DMS
    },
    {
        path: '/:lang/product/bim-model-management',
        Component: BIM
    },
    {
        path: '/:lang/product/cloud-infrastructure-and-security',
        Component: CIS
    },
    {
        path: '/:lang/product/plan-management',
        Component: PlanManagement
    },
    {
        path: '/:lang/product/information-management',
        Component: InfoManagement
    },
    {
        path: '/:lang/pricing',
        Component: Pricing
    },
    {
        path: '/:lang/imprint',
        Component: Imprint
    },
    {
        path: '/:lang/privacy-notice',
        Component: PrivacyNotice
    },
    {
        path: '/:lang/about-us',
        Component: AboutUs
    },
    {
        path: '/:lang/careers',
        Component: Career
    },
    {
        path: '/:lang/workflows',
        Component: Workflow
    },
    {
        path: '/:lang/workflows/:workflowName',
        Component: Workflow
    },
];

const RouteWrapper = () => {

    // console.count('RouteWrapper')
    const { i18n } = useTranslation();
    const pathname = useLocation().pathname;
    const languages = ['en', 'de'] // i18n.languages;
    const resolvedLanguage = i18n.resolvedLanguage;
    const isLng = pathname.split('/')[1];
    const redirectUrl  = languages.includes(isLng) ? pathname : generateUrl(resolvedLanguage, pathname)

    if (!languages.includes(isLng)) {
        return <Navigate to={redirectUrl} state={{ from: pathname }} replace />
    }
    return (
        <Routes>
            {
                routes.map(({ path, Component }) => (
                    <Route exact path={path} key={path} element={<Component />} />
            ))}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default function () {
    return (
        <I18nextProvider i18n={i18n} className={''}>
            <BrowserRouter>
                <LayoutWrapper>
                    <Suspense fallback={() => null}>
                        <RouteWrapper />
                    </Suspense>
                </LayoutWrapper>
            </BrowserRouter>
        </I18nextProvider>
    );
}